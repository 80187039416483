import React from "react";
import { useState } from "react";
import "../component/ProductContainer.css";
import {
  Card,
  Button,
  Modal,
  Container,
  Row,
  Col,
  Image,
} from "react-bootstrap";
import ImageNotFound from "../Assets/imageNotFound.png";
import { baseURL } from "../Store/Actions";

function ProductContainer(props) {
  // console.log(props.image);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const imageUrl = `${baseURL}/${props.image}`;
  const imageAlt = ImageNotFound;

  return (
    <div className="productContainer">
      <Card className="h-100 border-0 card-product ">
        {/* <Card.Img variant='top' src={imageUrl} fluid/>  */}
        {props.image !== null ? (
          <Card.Img className="mx-auto" variant="top" src={imageUrl} fluid />
        ) : (
          <Card.Img className="mx-auto" variant="top" src={imageAlt} fluid />
        )}
        <Card.Body className="d-flex flex-column ">
          <Card.Text className="productName">{props.name}</Card.Text>
          <Button
            size="sm"
            className="mt-auto w-100 text-nowrap btn-product"
            variant="primary"
            onClick={handleShow}
          >
            View more
          </Button>

          <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            animation={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <Container fluid>
                {window.innerWidth <= 768 ? (
                  <Row className="align-items-start">
                    <Col sm={12} className="text-center">
                      <h1 className="productNameModal">{props.name}</h1>
                    </Col>
                    <Col sm={12}>
                      <Row className="align-items-center">
                        <Image
                          className="productImage mx-auto d-block"
                          src={props.image ? imageUrl : imageAlt}
                          alt="productImage"
                        />
                      </Row>
                      <div className="productDescriptionContainer">
                        <p className="productDescription mt-1">
                          {props.description}
                        </p>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col sm={5} className="d-flex align-items-center">
                      <Image
                        className="productImage mx-auto d-block"
                        src={props.image ? imageUrl : imageAlt}
                        fluid
                      />
                    </Col>
                    <Col sm={7}>
                      <h1 className="productNameModal">{props.name}</h1>
                      <br />
                      <div className="productDescriptionContainer">
                        <p className="productDescription">
                          {props.description}
                        </p>
                      </div>
                    </Col>
                  </Row>
                )}
              </Container>
            </Modal.Body>

            <Modal.Footer></Modal.Footer>
          </Modal>
        </Card.Body>
      </Card>
    </div>
  );
}
export default ProductContainer;
