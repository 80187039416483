import { Pagination } from "react-bootstrap";
import '../component/PaginationFunction.css';
import { useEffect, useState } from "react";
function PaginationFunction({totalPages, currentPage, onChangePage}){

    const [size, setSize] = useState("lg");
    let items = []

    useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth < 769) {
            setSize(undefined); 
          } else {
            setSize("lg");
          }
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (currentPage > 1){
        items.push(
            <Pagination.Prev key="prev" onClick={()=> onChangePage(currentPage - 1)}/>
        )
    }

    for (let page = 1; page <= totalPages; page++){
        items.push(
            <Pagination.Item key={page} data-page={page} active={page === currentPage} onClick={()=> onChangePage(page)}>
                {page}
            </Pagination.Item>
        )
    }

    if (currentPage < totalPages){
        items.push(
            <Pagination.Next key="next" onClick={()=> onChangePage(currentPage + 1)}/>
        )
    }

    return (
        totalPages > 1
        ? (
            <Pagination 
                size={size}
                className="d-flex justify-content-center" 
            >
                {items}
            </Pagination>
        )
        : (
            null
        )        
    )
    
}

export default PaginationFunction