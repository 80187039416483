import React, { useEffect, useRef, useState } from "react";
import styles from "./SignUp.module.css";
import popUpStyles from "../component/PopUp.module.css";
import OTPStyles from "../Pages/PageOTP.module.css";
import validator from "validator";
import OTPemail from "../Assets/OTPemail.jpg";
import { BiLowVision, BiShow } from "react-icons/bi";
import PopUpOTP from "../component/PopUpOTP";
import { UserRegister } from "../Store/Actions/user";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import OtpInput from "react-otp-input";
import { redirect } from "react-router-dom";
import { loginUser } from "../Store/Actions/user";
import { axiosGet } from "../Store/Actions";
import {
  Button,
  Col,
  Container,
  Image,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  FormText,
  Modal,
  Row,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";
import LoadingSpinner from "../component/LoadingSpinner";
import errorIcon from "../Assets/ErrorIcon.png";
import { validateEmail } from "../Store/Actions/user";
import SuccessIcon from "../Assets/SuccessIcon.png";
import { sendOTP } from "../Store/Actions/user";
import Breadcrumb from "../component/Breadcrumb";

function SignUp() {
  const scrollRef = useRef(null);
  const timer = useRef(null);
  const [fullNameError, setFullNameError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [rePasswordError, setRePasswordError] = useState(null);
  const [signUpErrorMessage, setSignUpErrorMessage] = useState(null);

  // const [ErrorMessage, setErrorMessage] = useState("");

  const scrollTop = () => {
    scrollRef.current.scrollIntoView();
  };

  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");

  const [passwordShown, setPasswordShown] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [show, setShow] = useState(false);
  const [isLoading, setLoadingState] = useState(false);
  const [signUnError, setSignUpError] = useState(false);
  const [tokenlogin, setTokenLogin] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const maxFullName = 30;

  const maxEmail = 30;

  const minPhone = 10;
  const maxPhone = 15;

  const minPassword = 8;
  const maxPassword = 15;

  const genNewOTP = async () => {
    dispatch(await sendOTP({ email: email }));
  };

  const handleInputPassword = (string) => {
    const regex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/g;
    if (regex.test(string)) {
      return;
    }
    setPassword(string);
  };
  const handleInputRePassword = (string) => {
    const regex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/g;
    if (regex.test(string)) {
      return;
    }
    setRePassword(string);
  };

  // const validateForm = () => {
  //   const passwordsMatch = password === rePassword;
  //   const regex = /^(?=.*[A-Z]).{8,}$/;
  //   const hasValidPassword = regex.test(password);
  //   if (!passwordsMatch) {
  //     return ["Your passwords don’t match. Please enter your password again."];
  //   } else if (!hasValidPassword) {
  //     return [
  //       "Password must have at least 1 uppercase letter and a minimum length of 8 characters.",
  //     ];
  //   } else {
  //     return "";
  //   }
  // };

  useEffect(() => {
    // console.log("ran");
    // setFullName("");
    // setPhoneNumber("");
    // setEmail("");
    // setPassword("");
    // setRePassword("");
  }, []);

  const fullNameValidation = () => {
    //isEmpty
    if (fullName.trim().length === 0) {
      return [setFullNameError("Full Name must be filled."), false];
    }
    //maxChar
    if (fullName.trim().length > maxFullName) {
      return [
        setFullNameError(
          "Full Name must be no more than " + maxFullName + " characters."
        ),
        false,
      ];
    }
    return [setFullNameError(null), true];
  };

  const phoneNumberValidation = () => {
    //isEmpty
    if (phoneNumber.length === 0) {
      return [setPhoneError("Phone Number must be filled."), false];
    }
    //minChar
    if (phoneNumber.length < minPhone) {
      return [
        setPhoneError("Phone Number must be at least " + minPhone + " digits."),
        false,
      ];
    }
    //maxChar
    if (phoneNumber.length > maxPhone) {
      return [
        setPhoneError(
          "Phone Number must be no more than " + maxPhone + " digits."
        ),
        false,
      ];
    }
    return [setPhoneError(null), true];
  };

  const emailValidation = () => {
    //isEmpty
    if (email.trim().length === 0) {
      return [setEmailError("Email must be filled."), false];
    }
    //maxChar
    if (email.trim().length > maxEmail) {
      return [
        setEmailError(
          "Email must be no more than " + maxEmail + " characters."
        ),
        false,
      ];
    }
    //isNotEmail
    if (!validator.isEmail(email.trim())) {
      return [setEmailError("Input Email in this field."), false];
    }
    return [setEmailError(null), true];
  };

  const passwordValidation = () => {
    const regEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
    const hasValidPassword = regEx.test(password);
    //isEmpty
    if (password.length === 0) {
      return [setPasswordError("Password must be filled."), false];
    }
    //minChar
    if (password.length < minPassword) {
      return [
        setPasswordError(
          "Password must be at least " + minPassword + " characters."
        ),
        false,
      ];
    }
    //maxChar
    if (password.length > maxPassword) {
      return [
        setPasswordError(
          "Password must be no more than " + maxPassword + " characters."
        ),
        false,
      ];
    }
    //1 1 1
    if (!hasValidPassword) {
      return [
        setPasswordError(
          "Password must contain at least one lowercase letter, one uppercase letter, and one number."
        ),
        false,
      ];
    }
    return [setPasswordError(null), true];
  };

  const rePasswordValidation = () => {
    const regEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
    const hasValidPassword = regEx.test(password);
    if (
      password.length >= minPassword &&
      password.length < maxPassword &&
      hasValidPassword
    ) {
      //isEmpty
      if (rePassword.length === 0) {
        return [setRePasswordError("Re-Password must be filled."), false];
      }
      //Password === RePassword?
      if (password != rePassword) {
        return [
          setRePasswordError(
            "Your password don't match. Please enter your password again."
          ),
          false,
        ];
      }
      return [setRePasswordError(null), true];
    }
    return [setRePasswordError(null), false];
  };

  const validateAll = async () => {
    //Validations
    // console.log("===");
    const fullNameValid = fullNameValidation()[1];
    // console.log("Full name: " + fullNameValid);
    const phoneValid = phoneNumberValidation()[1];
    // console.log("Phone number: " + phoneValid);
    const emailValid = emailValidation()[1];
    // console.log("Email: " + emailValid);
    const passwordValid = passwordValidation()[1];
    // console.log("Password: " + passwordValid);
    const rePassValid = rePasswordValidation()[1];
    // console.log("Re-Password: " + rePassValid);
    // console.log("===");
    if (
      fullNameValid &&
      phoneValid &&
      emailValid &&
      passwordValid &&
      rePassValid
    ) {
      return true;
    }
    return false;
    // console.log(passValidation);
  };

  const handlePhoneInput = (string) => {
    setPhoneNumber(string.replace(/[^0-9]/g, ""));
  };

  const registerNewUser = async () => {
    try {
      const NewUser = {
        fullname: fullName,
        email: email,
        phone: phoneNumber,
        password: password,
      };
      const response = await UserRegister(NewUser);
      if (response.status === 201) {
        // console.log("Registration successful");
        return [true, response.status];
      }
      if (
        response.data.result === "Email already registered but not verified"
      ) {
        // console.log("im here");
        return [false, 409.1];
      }
      if (response.status === 409) {
        // console.log("Email already used");
        return [false, response.status];
      }
      if (response.status === 500) {
        // console.log("Try again later, server is down");
        return [false, 500];
      }
    } catch (e) {
      // console.log("here");
    }
  };

  // const handleFormSubmit = async (event) => {
  //   event.preventDefault();

  // if (fullName.trim() === "") {
  //   // setErrorMessage("Full Name is required to be filled");
  // } else if (phoneNumber.trim() === "") {
  //   // setErrorMessage("Phone Number is required to be filled");
  // } else {
  //   // const formValidationErrors = validateForm();
  //   if (formValidationErrors.length > 0) {
  //     // setErrorMessage(formValidationErrors.join(" "));
  //   } else {
  //     // setErrorMessage("");

  //     try {
  //       const NewUser = {
  //         fullname: fullName,
  //         email: email,
  //         phone: phoneNumber,
  //         password: password,
  //       };
  //       const response = await UserRegister(NewUser);
  //       navigate("/otp");
  //       if (response.status === 201) {
  //         console.log("Registration successful");
  //       }
  //     } catch (e) {}
  //   }
  // }
  // };
  const [showVerifyLater, setShowVerifyLater] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [otp, setOtp] = useState("");
  const [OTPError, setOTPError] = useState(null);
  const handleOtpSubmit = async () => {
    try {
      const response = await validateEmail(otp);
      if (response && response.status === 200) {
        // console.log("OTP verification successful");
        return true;
      }
    } catch (e) {
      return false;
    }
  };

  const OTPValidation = () => {
    //isEmpty
    if (otp.length === 0) {
      return [false, setOTPError("OTP must be filled.")];
    }
    if (otp.length < 6) {
      return [false, setOTPError("All OTP field must be filled.")];
    }
    return [true, setOTPError(null)];
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleShow = () => {
    setShow(!show);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowPass = () => {
    setShowPass(!showPass);
  };

  const loginNewAccount = async () => {
    // console.clear();
    setShowSuccess(false);
    dispatch(loginUser({ email, password }));

    const id = localStorage.getItem("id");
    try {
      const data = await axiosGet(`user/iduser?id_user=${id}`);
      setFullNameQ(data.fullname);
      setEmailQ(data.email);
      setPhone(data.phone);
    } catch (error) {
      // console.log(error);
    }
    navigate("/");
    window.location.reload();
  };
  const setFullNameQ = (fullName) => {
    // console.log("Full Name:", fullName);
    localStorage.setItem("fullName", fullName);
  };

  const setEmailQ = (email) => {
    // console.log("Email:", email);
    localStorage.setItem("email", email);
  };

  const setPhone = (phone) => {
    // console.log("Phone:", phone);
    localStorage.setItem("phone", phone);
  };

  return (
    <>
      <div className={styles.breadCrumb}>
        <Breadcrumb />
      </div>
      <Container className={styles.Container}>
        {/* <Button onClick={() => setShowSuccess(true)}>Show Success</Button>
      <Button onClick={() => setShowOTP(true)}>Show OTP</Button> */}
        {/* Modal OTP */}
        <Modal
          show={showOTP}
          centered
          className="d-flex justify-content-center"
          // onHide={() => setShowOTP(false)}
        >
          {/* <div className={OTPStyles.OuterOTPContainer}> */}
          <Modal.Header style={{ width: "100%", padding: 0 }}>
            <Modal.Title className={OTPStyles.OTPtitle}>
              Verify Email
            </Modal.Title>
          </Modal.Header>
          <div className={OTPStyles.PageOTPContainer}>
            <Image className={OTPStyles.OTPemail} src={OTPemail} />
            <Modal.Body style={{ padding: 0 }}>
              <div className={OTPStyles.PleaseEnter}>
                Please enter the 6 digit code sent to your email
                <div>{email}</div>
              </div>
              <div className={OTPStyles.OTPinput}>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span style={{ width: "10px" }}></span>}
                  renderInput={(props) => <input {...props} />}
                  inputStyle={OTPStyles.OTPField}
                />
              </div>
              {OTPError === null ? null : (
                <center className="m-3" style={{ color: "#BE1620" }}>
                  {OTPError}
                </center>
              )}
            </Modal.Body>

            <Modal.Footer className={OTPStyles.ButtonContainer}>
              <Button
                className={OTPStyles.OTPbutton}
                onClick={async () => {
                  const OTPValid = OTPValidation()[0];
                  if (OTPValid) {
                    const response = await handleOtpSubmit();
                    // console.log(response);
                    if (response) {
                      setShowOTP(false);
                      setShowSuccess(true);
                    } else {
                      setOtp("");
                      setOTPError("Please insert the correct OTP.");
                    }
                  }
                }}
              >
                Sign Up
              </Button>
            </Modal.Footer>
          </div>
          {/* </div> */}
        </Modal>
        {/* modal success */}
        <Modal
          className={popUpStyles.ModalSuccess}
          style={{ padding: 0 }}
          centered
          show={showSuccess}
          onHide={async () => await loginNewAccount()}
        >
          <ModalHeader
            className={popUpStyles.ModalHeaderSuccess}
            style={{ padding: 0 }}
          >
            <h1 className={popUpStyles.ModalHeaderTitleSuccess}>
              Verify Email
            </h1>
          </ModalHeader>
          <Container className={popUpStyles.ModalBodyContainerSuccess}>
            <ModalBody
              className={popUpStyles.ModalBodyContentSuccess}
              style={{ padding: 0 }}
            >
              <img
                className={popUpStyles.ModalBodyContentImageSuccess}
                src={SuccessIcon}
              />
              <div style={{ padding: 0 }}>
                You have successfully verified the account.
              </div>
            </ModalBody>
            <ModalFooter
              style={{ padding: 0 }}
              className={popUpStyles.ModalFooterSuccess}
            >
              <Button
                onClick={async () => {
                  await loginNewAccount();
                }}
                className={popUpStyles.ModalFooterButtonSuccess}
              >
                OK
              </Button>
            </ModalFooter>
          </Container>
        </Modal>
        <Modal show={signUnError} onHide={() => setSignUpError(false)} centered>
          <Modal.Body className={styles.ErrorPopUp}>
            <Modal.Header>
              <img className={styles.ErrorImage} src={errorIcon} alt="" />
            </Modal.Header>
            <Modal.Footer>{signUpErrorMessage}</Modal.Footer>
          </Modal.Body>
        </Modal>
        <Modal
          show={showVerifyLater}
          onHide={() => setShowVerifyLater(false)}
          centered
        >
          <Col className={`${styles.ErrorPopUp} p-4`}>
            <Modal.Header>
              <img className={styles.ErrorImage} src={errorIcon} alt="" />
            </Modal.Header>
            <Modal.Body>Email already registered but not verified</Modal.Body>
            <Modal.Footer>
              <Row className="d-flex justify-content-center">
                <Button
                  className={styles.VerifyLaterButton}
                  onClick={() => {
                    setShowVerifyLater(false);
                    genNewOTP();
                    setShowOTP(true);
                  }}
                >
                  Verify Account
                </Button>
              </Row>
            </Modal.Footer>
          </Col>
        </Modal>
        <Form ref={scrollRef} className={`${styles.LoginContent}`}>
          {/* <Button onClick={() => setShowVerifyLater(true)}></Button> */}
          <div className={styles.Title}>Sign Up</div>

          <FormGroup className={styles.FullName}>
            <FormLabel className={styles.FullNameLabel}>Full Name</FormLabel>
            <FormControl
              placeholder="Full Name"
              className={styles.FullNameBox}
              // className="form-control-FullNameBox"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
            {/* {fullNameError ?? <ErrorMessage message={fullNameError} />} */}
            {fullNameError != null ? (
              <FormText className={styles.ErrorMessageValidation}>
                {fullNameError}
              </FormText>
            ) : null}
          </FormGroup>
          <FormGroup className={styles.PhoneNum}>
            <FormLabel className={styles.PhoneNumLabel}>
              Phone Number
              <div>(ex: 0816555423 or 0215123121)</div>
            </FormLabel>
            <FormControl
              className={styles.PhoneNumBox}
              placeholder="Phone Number"
              value={phoneNumber}
              // type="number"
              onChange={(e) => handlePhoneInput(e.target.value)}
            />

            {phoneError != null ? (
              <FormText className={styles.ErrorMessageValidation}>
                {phoneError}
              </FormText>
            ) : null}
            {/* {phoneNumber.trim() === "" && (
            <FormText className={styles.PNErrorMessage}>
              Phone number is required
            </FormText>
          )} */}
          </FormGroup>
          <FormGroup className={styles.EmailText}>
            <FormLabel className={styles.EmailLabel}>Email</FormLabel>
            <FormControl
              placeholder="Email"
              className={styles.EmailBox}
              value={email}
              onChange={(e) => setEmail(e.target.value.trim())}
            />
            {emailError != null ? (
              <FormText className={styles.ErrorMessageValidation}>
                {emailError}
              </FormText>
            ) : null}
          </FormGroup>
          <FormGroup className={styles.Password}>
            <FormLabel className={styles.PasswordLabel}>Password</FormLabel>
            <div className={styles.PasswordAndShow}>
              <FormControl
                placeholder="Password"
                className={styles.PasswordBox}
                type={show ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  handleInputPassword(e.target.value.trim());
                  // console.log(regex.test(e.target.value));
                  // if (regex.test(e.target.value.trim())) {
                  //   return;
                  // }
                  // setPassword(e.target.value.trim());
                }}
              />
              {!show ? (
                <BiShow className={styles.ShowPass} onClick={handleShow} />
              ) : (
                <BiLowVision className={styles.ShowPass} onClick={handleShow} />
              )}
            </div>
            {passwordError != null ? (
              <FormText className={styles.ErrorMessageValidation}>
                {passwordError}
              </FormText>
            ) : null}
          </FormGroup>
          <FormGroup className={styles.RePassword}>
            <FormLabel className={styles.RePasswordLabel}>
              Re-Enter Password
            </FormLabel>
            <div className={`${styles.RePasswordAndShow}`}>
              <FormControl
                placeholder="Re-Enter Password"
                type={showPass ? "text" : "password"}
                className={styles.RePasswordBox}
                value={rePassword}
                onChange={(e) => handleInputRePassword(e.target.value.trim())}
                onPaste={(e) => {
                  e.preventDefault();
                  return;
                }}
                // style={{
                //   borderColor: validateForm().length === 0 ? "green" : "red",
                // }}
              />
              {!showPass ? (
                <BiShow
                  className={styles.ReShowPass}
                  onClick={handleShowPass}
                />
              ) : (
                <BiLowVision
                  className={styles.ReShowPass}
                  onClick={handleShowPass}
                />
              )}
            </div>
            {rePasswordError != null ? (
              <FormText className={styles.ErrorMessageValidation}>
                {rePasswordError}
              </FormText>
            ) : null}
            {/* {validateForm().length > 0 && (
            <FormText
              className={styles.PasswordAlert}
              style={{ color: "#BE1620" }}
            >
              {validateForm().join(" ")}
            </FormText>
          )} */}
          </FormGroup>

          <div className={`${styles.SignUp}`}>
            {!isLoading ? (
              <Button
                className={styles.SignUpButton}
                // type="submit"
                // onClick={handleFormSubmit}
                onClick={async () => {
                  if (await validateAll()) {
                    setLoadingState(true);
                    const response = await registerNewUser();
                    // console.log(response);
                    if (response[0]) {
                      setLoadingState(false);
                      setShowOTP(true);
                    } else {
                      if (response[1] === 409) {
                        setLoadingState(false);
                        setEmail("");
                        setSignUpErrorMessage(
                          "Failed to sign up, email already used"
                        );
                        setEmailError("Input a different email");
                        setSignUpError(true);
                      } else if (response[1] === 409.1) {
                        setLoadingState(false);
                        setShowVerifyLater(true);
                        // console.log("Email not verified");
                      } else {
                        setLoadingState(false);
                        setSignUpErrorMessage(
                          "Failed to sign up, try again later"
                        );
                        setSignUpError(true);
                      }
                    }
                  } else {
                    scrollTop();
                  }
                }}
              >
                Sign Up
              </Button>
            ) : (
              <LoadingSpinner />
            )}
          </div>
          {/* <div>
          {ErrorMessage && (
            <div className={styles.ErrorMessage}>{ErrorMessage}</div>
          )}
        </div> */}
          {/* <Button onClick={() => setShowOTP(true)}>OTP</Button> */}
        </Form>
      </Container>
    </>
  );
}

export default SignUp;
