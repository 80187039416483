import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import styles from './ProductCategoryButton.module.css';

function ProductCategoryButton({onIdClick, id, sub, name, subData, active, linkName}){

    
    
    const handleClick = () => {
        onIdClick(id, sub, subData, false, linkName); // Pass the value
    };
    

    return(
        <Button 
            className= { active
                ? styles.activeCategoryButton
                : styles.categoryButton
            }
            onClick={()=> handleClick()}
        >
            <div className={styles.textButton}> 
                {name} 
            </div>
        </Button>
    );
}
export default ProductCategoryButton