import React from "react";
import "./ProductShowSubcategory.css";
import SubcategoryContainer from "./SubcategoryContainer";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PaginationFunction from "./PaginationFunction";
import { useCallback, useState, useEffect } from "react";
import { getSubCategoryList } from "../Store/Actions/product";

function ProductShowSubcategory({ data, onClick }) {
  const [showSubProduct, setShowSubProduct] = useState(null);
  const [idSubCategory, setIdSubCategory] = useState(null);
  const [subLinkName, setSubLinkName] = useState(''); // item breadccrumbs sub
  const subCatData = data;
  // const handleClick = useCallback((showSubProduct, idSubCategory, subLinkName) => {
  //   setShowSubProduct(showSubProduct);
  //   setIdSubCategory(idSubCategory);
  //   setSubLinkName(subLinkName);
  //   console.log("showSubProduct:", showSubProduct);
  //   console.log("showSubProduct:", idSubCategory);
  //   console.warn("sublinkname:", subLinkName);
  // }, []);
  // const passingshowSubProduct = () => {
  //   onClick(showSubProduct, idSubCategory, subLinkName);
  // };
  // useEffect(() => {
  //   passingshowSubProduct();
  // }, [showSubProduct, idSubCategory, subLinkName]);
  // console.warn(firstLinkName)
  return (
    <div className="productShowSubcategory">
      <Container fluid>
        <Row>
          {subCatData.map((e) => {
            return (
              <Col className="showSubcategoryCol"
                key={e.id}
                xs={6} 
                xl={4}
              >
                <SubcategoryContainer
                  name={e.name_sub_category}
                  onClick={onClick}
                  idSubCategory={e.id}
                  image={e.product_image}
                  subLinkName={e.link_name}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

export default ProductShowSubcategory;
