export function extractText(htmlString, startTag, endTag) {

    const startIndex = htmlString.indexOf(startTag);
    const endIndex = htmlString.indexOf(endTag, startIndex);

    // console.log(htmlString + startTag +  endTag)

  
    if (startIndex !== -1 && endIndex !== -1) {
      const extractedText = htmlString.slice(startIndex, endIndex + endTag.length).trim();
      // console.log(extractedText)
      return extractedText;

    } else {
      return null; 
    }
}