import { axiosGet, axiosPost } from "./index.js";

export const getAboutUs = () => {
  return async (dispatch) => {
    try {
      const about = await axiosGet("about-us");
      // if(products.type === 'error') throw products

      dispatch({
        type: "SET_ABOUT",
        about,
      });

      // console.log(about);
      return "success";
    } catch (e) {
      throw e;
    }
  };
};

export const getAboutPhoto = () => {
  return async (dispatch) => {
    try {
      const aboutphoto = await axiosGet("about-us-photo");
      // if(products.type === 'error') throw products

      dispatch({
        type: "SET_ABOUTPHOTO",
        aboutphoto,
      });

      // console.log(aboutphoto);
      return "success";
    } catch (e) {
      throw e;
    }
  };
};

export const getSocialMedia = () => {
  return async (dispatch) => {
    try {
      const socialmedia = await axiosGet("social-media");
      // if(products.type === 'error') throw products

      dispatch({
        type: "SET_SOCIALMEDIA",
        socialmedia,
      });

      // console.log(socialmedia);
      return "success";
    } catch (e) {
      throw e;
    }
  };
};

export const getContactInfo = () => {
  return async (dispatch) => {
    try {
      const contactinfo = await axiosGet("company-profile");
      // if(products.type === 'error') throw products

      dispatch({
        type: "SET_CONTACTINFO",
        contactinfo,
      });

      // console.log(contactinfo);
      return "success";
    } catch (e) {
      throw e;
    }
  };
};

export const getHomePhotos = async () => {
  return async (dispatch) => {
    try {
      const response = await axiosGet("home-photo");
      // console.log("response:", response[0].data);
      return response[0].data;

      // if(products.type === 'error') throw products

      //   dispatch({
      //     type: "SET_HOMEPHOTOS",
      //     homephotos,
      //   });
    } catch (e) {
      throw e;
    }
  };
};

export const postContactUs = (request) => {
  return async () => {
    try {
      const data = await axiosPost("contact-us", request);
      // const result = data.result[0]
      // if(data.type === 'error') throw data
      // console.log(data);
      return "success";
    } catch (e) {
      throw e;
    }
  };
};

export const postSubscribe = (email) => {
  return async () => {
    try {
      const data = await axiosPost("subscribe", email);
      // console.log(data);
      // console.log("success");
    } catch (e) {
      // console.error(e.response.data.result);
      return e.response.data.result;
    }
  };
};
