import NavigationBar from "./Navbar";
import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  redirect,
  useNavigate,
} from "react-router-dom";
// import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import ProductPage from "./Pages/ProductPage";
import "./App.css";
import Footer from "./component/Footer";
import SignUp from "./Pages/SignUp";
import EditProfile from "./Pages/EditProfile";
import ChangePassword from "./Pages/ChangePassword";
import PageNotFound from "./Pages/PageNotFound/PageNotFound";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "./Store/Actions";
import { datauser } from "./Store/Actions/user";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import Body from "./component/Body";
import LoadingSpinner from "./component/LoadingSpinner";
import ProductRedirect from "./Pages/ProductRedirect/ProductRedirect";
import PageUnderMaintenance from "./Pages/PageUnderMaintenance/PageUnderMaintenance";

let category_list = {};

const App = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fullName, setFullName] = useState("");
  const [tokenlogin, setTokenLogin] = useState("");
  let link = '';

  const showCategoryList = async () => {
    await getCategoryList();
  };

  const redirectTo = (path) => {
    return redirect(`${path}`);
  }

  const getCategoryList = async () => {
    // getAPI
    try {
      const response = await axios.get(baseURL + "/category");
      category_list = response.data.result;
      // console.warn(category_list)
      // console.warn(category_list[0].link_name)
      // setLink(category_list[0].link_name);
      // link = category_list[0].link_name;
      localStorage.setItem('categoryOne', category_list[0].link_name);
      // setProductPath(`/product/${link}`);
      // console.warn(link);
      // localStorage.setItem('categoryDataList', JSON.stringify(category_list));
      // link = category_list[0].link_name;
    } catch (e) {
      // console.error(e.message);
      // console.error(e)
      // console.error(e.code)
      const error = e.code
      if (error === "ERR_NETWORK"){
        console.clear()
        console.log(error)
        console.log("navigate to maintanence")
        // belum bisa navigate
        // return(
        //   <Navigate to="*" />
        // )
      }      
    }
  };

  const getDataUser = async (id) => {
    try {
      dispatch(datauser(id, setFullNameQ, setEmailQ, setPhone));
    } catch (e) {
      console.error("Error fetching user id");
    }
  };

  const setFullNameQ = (fullName) => {
    // console.log("Full Name:", fullName);
    localStorage.setItem("fullName", fullName);
    setFullName(fullName);
  };

  const setEmailQ = (email) => {
    // console.log("Email:", email);
    localStorage.setItem("email", email);
  };

  const setPhone = (phone) => {
    // console.log("Phone:", phone);
    localStorage.setItem("phone", phone);
  };

  // function set role menu
  const [isLoading, setIsLoading] = useState(true);
  const [isApiWorking, setIsApiWorking] = useState(true);
  const [roleMenu, setRoleMenu] = useState([]);
  let webMenu = [];
  // let altRoutes = []; 
  // let roleMenu = [];
  // const [webMenu, setWebMenu] = useState([]);
  const getRoleMenu = async ( idRole ) => {
    
    try {
      const response = await axios.get(baseURL + `/role-menu?id_role=${idRole}`);
      // console.log(response.data.result[0].data);

      console.log("targetRoleMenu");
      const targetRoleMenu = response.data.result[0].data;
      console.log(targetRoleMenu);

      const filteredRoleMenu = webMenu.map( web => ({
        name_menu: web.name_menu,
        is_visible: String((targetRoleMenu.find(roleMenu => web.name_menu === roleMenu.name_menu) || {}).is_visible || 'false'),
      }));

      console.log("filteredRoleMenu");
      console.log(filteredRoleMenu);

      const altRoutes = routes.map(route => {
        const matchingData = filteredRoleMenu.find(data => data.name_menu === route.name_menu);
        if (matchingData) {
          return { ...route, visible: matchingData.is_visible };
        } else {
          return route;
        }
      });

      console.log("routes");
      console.log(routes);

      console.log("altRoutes");
      console.log(altRoutes);

      setRoleMenu(altRoutes);
      // roleMenu = altRoutes;
    } catch (e) {
      console.error("Get Role Menu Failed");
    }

  };
  const getWebMenu = async () => {
    try {
      const response = await axios.get(baseURL + `/menu`);
      const filteredWebMenu = response.data.result[0].data.filter(data => data.platform_type === "website")
      console.log(filteredWebMenu);
      // setWebMenu(filteredWebMenu);
      webMenu = filteredWebMenu;
    } catch (e) {
      setIsApiWorking(false);
      console.error("Get Web Menu Failed");
      // navigate(`/under-maintanence`);
      // isApiWorking = false
    }
  };
  //
  useEffect(() => {
    const id = localStorage.getItem(`id`);
    const getAPI_Start = async () =>{
      await getWebMenu();
      setIsLoading(true);
    }
    getAPI_Start();
    link = localStorage.getItem('categoryOne');
    if (id) {
      const getAPI_User = async () =>{
        console.log("get user by ID")
        // getDataUser(id);
        // await getWebMenu(); 
        localStorage.setItem('count', 0);
        await getCategoryList();
      }
      getAPI_User();
      const storedFullName = localStorage.getItem("fullName");
      const loginToken = localStorage.getItem("token");
      const storedIdRole = localStorage.getItem("idRole");
      if (storedFullName && storedIdRole && loginToken) {
        console.log("Ada akun user")
        // setIsLoading(true);
        setFullName(storedFullName);
        setTokenLogin(loginToken);
        // setIdRole(storedIdRole);
        // console.log(fullName);
        console.log("id_role :", storedIdRole);
        // getRoleMenu(storedIdRole);
        const getAPI = async () =>{          
          // console.log(isLoading);
          await getRoleMenu(storedIdRole);
          // console.log(roleMenu);
          setIsLoading(false);
          // console.log(isLoading);
        }
        getAPI();
      } 
    } else {
      const getAPI_Short = async () =>{
        console.log("Short API, ID tidak ada")
        console.log(isLoading)
        // await getWebMenu(); 
        await getCategoryList();
        console.log(link);
        setIsLoading(false);
      }
      getAPI_Short();
      console.log(isLoading)  
    }
    console.log(isLoading)
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isLoading
        ) {
        console.log("Reloading page due to timeout");
        window.location.reload();
      }
    }, 1000);
    const cleanup = () => {
      clearTimeout(timeoutId);
    };
    return cleanup;
  }, [ isLoading ]);

  let routes = [
    // { // 0
    //   visible: "false",
    //   name_menu: "product",
    //   path: "/product",
    //   element: <ProductRedirect />,
    // },
    // { // 1
    //   visible: "false",
    //   name_menu: "product",
    //   path: "/product/:category/:subcategory?",
    //   element: <ProductPage />,
    // },
    // { // 2
    //   visible: "false",
    //   name_menu: "contact-us",
    //   path: "/contact-us",
    //   element: <ContactUs />,
    // },


    // TEST ALL OPEN //
    { // 0
      visible: "true",
      name_menu: "product",
      path: "/product",
      element: <ProductRedirect />,
    },
    { // 1
      visible: "true",
      name_menu: "product",
      path: "/product/:category/:subcategory?",
      element: <ProductPage />,
    },
    { // 2
      visible: "true",
      name_menu: "contact-us",
      path: "/contact-us",
      element: <ContactUs />,
    },
    // TEST ALL OPEN //
  ];

  return isApiWorking ? (
  isLoading ? ( 
    <div className="loadRoleSpinner">
      <LoadingSpinner />  
    </div> 
    ) 
    : (
    <Router>
      <div className="App">
        <NavigationBar 
          username={fullName}
          productVisible={fullName && roleMenu.length > 0 ? roleMenu[0].visible : routes[0].visible}
          contactVisible={fullName  && roleMenu.length > 2 ? roleMenu[2].visible : routes[2].visible}
        />
      </div>
      <Routes>
        <Route exact path="/" element={<Body 
          ourProductVisible={
            fullName && roleMenu.length > 0 ? roleMenu[0].visible : routes[0].visible 
          }
        /> } />
        <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route exact path="*" element={<PageNotFound />} />
        <Route exact path="/sign-up" element={<SignUp />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/edit-profile" element={<EditProfile updateNavbarFullName={setFullName} />} />
        <Route exact path="/edit-profile/change-password" element={<ChangePassword />} />
        { roleMenu.length > 0
          ? roleMenu.map((route, idx) => (
            route.visible === "true" && (<Route key={idx} exact path={route.path} element={route.element} />)
          ))
          : routes.map((route, idx) => (
            route.visible === "true" && (<Route key={idx} exact path={route.path} element={route.element} />)
          ))
        }
      </Routes>
      <Footer 
        newsletterVisible={true}
        // newsletterVisible={fullName}
        productVisible={fullName  && roleMenu.length > 0 ? roleMenu[0].visible : routes[0].visible}
        contactVisible={fullName  && roleMenu.length > 2 ? roleMenu[2].visible : routes[2].visible}
      />
    </Router>
  )) 
  : (
    <PageUnderMaintenance />
  )
};

export default App;

