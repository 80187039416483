import { useState } from "react";
import { Button, Nav, Navbar, Offcanvas } from "react-bootstrap";
import styles from "./HomeShowCanvas.module.css";
import { Link } from "react-router-dom";
import BurgerMenu from "../Assets/BurgerMenu.svg";
import closeBtn from "../Assets/closeBtn.svg";

const HomeShowCanvas = (
  {
    productVisible,
    contactVisible,
  }
) => {
  const [showCanvas, setShowCanvas] = useState(false);
  const handleOpenCanvas = () => {
    setShowCanvas(true);
  };
  const handleCloseCanvas = () => {
    setShowCanvas(false);
  };

  return (
    <div className={styles.showCanvasContainer}>
      <Button
        className={styles.BurgerButton}
        style={{ padding: 0 }}
        onClick={handleOpenCanvas}
      >
        <img className={styles.Burger} src={BurgerMenu} alt="" />
      </Button>
      <Offcanvas
        className={styles.showCanvas}
        show={showCanvas}
        onHide={handleCloseCanvas}
      >
        <Offcanvas.Header>
          <Offcanvas.Title />
          <button className={styles.closeBtn} onClick={handleCloseCanvas}>
            <img className={styles.imgClsBtn} src={closeBtn} alt="" />
          </button>
        </Offcanvas.Header>
        <div className={styles.bodyContainer}>
          <hr className={styles.Gap} />
          <Offcanvas.Body style={{ padding: 0 }}>
            <Nav>
              { productVisible === "true" &&
                <Nav.Link
                  style={{ paddingTop: "10px" }}
                  className={styles.showCanvasButton}
                  as={Link}
                  onClick={handleCloseCanvas}
                  to="/product"
                >
                  Product
                </Nav.Link>
              }
              <Nav.Link
                style={{ paddingTop: "10px", marginTop: "4px" }}
                className={styles.showCanvasButton}
                as={Link}
                onClick={handleCloseCanvas}
                to="/about-us"
              >
                About Us
              </Nav.Link>
              { contactVisible === "true" &&
                <Nav.Link
                  style={{ paddingTop: "10px", marginTop: "4px" }}
                  className={styles.showCanvasButton}
                  as={Link}
                  onClick={handleCloseCanvas}
                  to="/contact-us"
                >
                  Contact Us
                </Nav.Link>
              }
            </Nav>
          </Offcanvas.Body>
        </div>
      </Offcanvas>
    </div>
  );
};

export default HomeShowCanvas;
