import { config } from "@fortawesome/fontawesome-svg-core";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./index.js";
import axios from "axios";

export const loginUser = (user) => {
  return async () => {
    try {
      // console.log(user, "email", "password");
      const data = await axiosPost("user/login", user);
      const result = data.result[0];
      localStorage.setItem("id", result.id);
      localStorage.setItem("token", result.token.token);
      // if(data.type === 'error') throw data
      // console.log(data);
      return [true, data];
    } catch (e) {
      return [false, e.response.data.result];
      // throw e;
    }
  };
};

export const userphoto = async (userId, setUserPhoto) => {
  try {
    const response = await axiosGet(`user-photo?id_user=${userId}`);
    // console.log(response);
    // console.log(userId);

    if (
      response.length > 0 &&
      response[0].data &&
      response[0].data.length > 0
    ) {
      const userphoto = response[0].data[0].image_user_photo;
      setUserPhoto(userphoto);
      // console.log("User Photo:", userphoto);
    }
  } catch (error) {
    // console.error("Error fetching user photo:", error);
  }
};

export const datauser = async (userId, setFullName, setEmail, setPhone, setIdRole) => {
  try {
    const data = await axiosGet(`user/iduser?id_user=${userId}`);
    // console.log(data);
    setFullName(data.fullname);
    setEmail(data.email);
    setPhone(data.phone);
    setIdRole(data.id_role)
    return "success";
  } catch (e) {}
};
const setFullName = (fullName) => {
  // console.log("Full Name:", fullName);
  localStorage.setItem("fullName", fullName);
};

const setEmail = (email) => {
  // console.log("Email:", email);
  localStorage.setItem("email", email);
};

const setPhone = (phone) => {
  // console.log("Phone:", phone);
  localStorage.setItem("phone", phone);
};

const setIdRole = (id_role) => {
  localStorage.setItem("idRole", id_role)
}

const userId = localStorage.getItem("id");
datauser(userId, setFullName, setEmail, setPhone, setIdRole);

export const UserRegister = async (NewUser) => {
  try {
    const response = await axiosPost("user", NewUser);
    // console.log("API Response:", response);
    // console.log("status api:", response.status);
    return response;
  } catch (e) {
    return e.response;
    // throw e;
  }
};

export const sendOTP = async (email) => {
  try {
    const response = await axiosPost(`user/otprequest`, email);
    // console.log(response);
    return "success";
  } catch (e) {}
};
export const validateEmail = async (otp) => {
  try {
    const response = await axiosPost("user/verify-email", { otp });
    // console.log(response);
    return response;
  } catch (e) {}
};

export const UserForgotPassword = async (email) => {
  try {
    const response = await axiosPost("user/forgotpassword", { email });
    // console.log(response);
    return response;
  } catch (e) {}
};

export const logoutUser = () => {
  return () => {
    localStorage.removeItem("token");
    localStorage.removeItem("fullname");
    localStorage.removeItem("email");
    localStorage.removeItem("phone");
    return "succcess";
  };
};

export const changepass = async (email, oldPassword, newPassword) => {
  try {
    // const headers = { 'Authorization': `Bearer ${token}` };
    const response = await axiosPut(`user`, {
      email,
      oldPassword,
      newPassword,
    });
    // console.log("Full Response:", response);
    // console.log("Response Data:", response.data);
    return "success";
  } catch (e) {
    throw e;
  }
};

export const changeuserdata = async (userData) => {
  try {
    const response = await axiosPut("user/data", userData);
    // console.log(response.data);
    // console.log(response);
    // console.log(response.status);
    if (response.status === 201) {
      localStorage.setItem(`fullName`, userData.fullname);
      return response;
    }
  } catch (e) {
    throw e;
  }
};

// export const adduserphoto = async (formData, Photoconfig) => {
//   try {
//     const response = await axiosPost('user-photo', formData, Photoconfig)
//     console.log(response)
//     console.log(response.status)
//   } catch (e) {
//     throw e;
//   }
// }
export const adduserphoto = async (formData, isPhotoUpload = true) => {
  try {
    const response = await axiosPost("user-photo", formData, isPhotoUpload);
    // console.log("Response:", response);
    // console.log("Response status:", response.status);
  } catch (e) {
    // console.error("Error uploading user photo:", e);
    throw e;
  }
};

export const deletephoto = async (userId) => {
  try {
    const response = await axiosDelete(`user-photo/${userId}`);
    // console.log(response);
  } catch (e) {
    // console.error("failed delete photo", e);
    throw e;
  }
};

export const updateUserPhoto = async (formData) => {
  try {
    const response = await axiosPut(`user-photo`, formData);
    // console.log(response);
  } catch (error) {
    throw error;
  }
};
