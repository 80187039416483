import React, { useCallback, useRef } from "react";
import "./Body.css";
import BodyContent from "../Assets/BodyContent.jpg";
import Spider from "../Assets/Spider.png";
import Laba2 from "../Assets/Laba2.png";
import logoSilstar from "../Assets/logo Silstar.png";
import rightVector from "../Assets/rightVector.svg";
// import rightVector from '../Assets/rightVector.png';
import VectorCarousel from "../Assets/VectorCarousel.png";
// import AboutUsImage from "../Assets/aboutus.jpg";
import VectorAboutUs from "../Assets/VectorAboutUs.png";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  getHomePhotos,
  getAboutPhoto,
  getAboutUs,
} from "../Store/Actions/company";
import { useDispatch, useSelector } from "react-redux";
import { axiosGet } from "../Store/Actions";
import {
  BiChevronLeft,
  BiChevronRight,
  BiRadioCircle,
  BiRadioCircleMarked,
} from "react-icons/bi";
import carouselIndexButton from "../Assets/ImageCarouselIndexButton.svg";
import carouselIndexButtonClicked from "../Assets/ImageCarouselIndexButtonClicked.svg";
import labaFontLogo from "../Assets/Laba2.png";
import spiderFontLogo from "../Assets/Spider.png";
import silstarFontLogo from "../Assets/logo Silstar.png";
import { extractText } from "../Functions/TextSeparator";
import HomeProduct from "./HomeProduct";
import { baseURL } from "../Store/Actions";
import ImageSlider from "./image_slider/image_slider";

function Body({ ourProductVisible }) {
  // const navigate = useNavigate();
  const timer = useRef(null);
  const dispatch = useDispatch();
  const { aboutphoto, about } = useSelector((state) => state.company);
  const [currIdx, setImageIdx] = useState(0);
  var [homePhotos, setHomePhotos] = useState([{}]);
  const showAboutPhoto = async () => {
    try {
      dispatch(getAboutPhoto());
    } catch (e) {
      // navigate("/under-maintanence");
      console.clear();
      console.log("ERROR WOI");

    }
  };
  const showAboutUs = async () => {
    try {
      dispatch(getAboutUs());
    } catch (e) {
      // console.log(e);
    }
  };
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const setWindowWidth = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    showAboutUs();
    showAboutPhoto();
    initState();
  }, []);

  const initState = async () => {
    // console.log("body ran");
    const getHomePhotos = async () => {
      const res = await axiosGet("home-photo").then((res) => {
        setHomePhotos(res[0].data);
      });
    };
    await getHomePhotos();
    // console.log(homePhotos);
    // console.log(`${baseURL}/${homePhotos[0].image_home}`);
    window.addEventListener("resize", setWindowWidth);
    return () => {
      window.removeEventListener("resize", setWindowWidth);
    };
  };
  const aboutUsImage = `${baseURL}/${aboutphoto?.[0]?.data?.[0].image_about_us}`;
  const mobileAboutText = extractText(
    `${about?.[0]?.data?.[0].about}`,
    "<p",
    "/p>"
  );
  // console.log(mobileAboutText);
  const mobileAboutHeader = extractText(
    `${about?.[0]?.data?.[0].about}`,
    "<h2",
    "/h2>"
  );
  // console.log(mobileAboutHeader);

  const parentWidth = useCallback(() => {
    return window.innerWidth;
  }, [window.innerWidth]);

  // console.log(about?.[0]?.data?.[0].about)
  // console.log(homePhotos);
  return (
    <div className="Body">
      <div className="imgSliderContainer">
        <ImageSlider imgs={homePhotos} width={parentWidth()} />
      </div>
      <Image className="VectorCarousel w-100" src={VectorCarousel} fluid />
      <Container>
        {screenWidth <= 768 ? (
          <Row>
            <div className="HomeAboutUs d-flex w-100 justify-content-between align-items-center">
              <div className="DefaultTitleHomePage">ABOUT US</div>
              <Link className="HomePageLinkButton" to="/about-us">
                View more
                <Image className="rightVector" src={rightVector} />
              </Link>
            </div>
            {!aboutphoto?.[0]?.data?.[0].image_about_us ? null : (
              <Image
                className="AboutUsHomeImage w-100"
                src={aboutUsImage}
                fluid
              />
            )}
            <div className="DefaultNormalText">
              <div
                className="CustomHomeTextBox-1"
                dangerouslySetInnerHTML={{ __html: mobileAboutText }}
              />
            </div>
          </Row>
        ) : (
          <Row>
            <div className="HomeAboutUs DefaultTitleHomePage">ABOUT US</div>
            <Row className="flex-nowrap overflow-hidden">
              <div className="d-flex flex-column CustomHomeTextBox-2 ">
                <div className="customHomePageMargin DefaultNormalText">
                  <div
                    className="customHomePageHeader"
                    dangerouslySetInnerHTML={{ __html: mobileAboutHeader }}
                  />
                  <div dangerouslySetInnerHTML={{ __html: mobileAboutText }} />
                </div>
                <Link
                  className="mt-auto mb-4 HomePageLinkButton"
                  to="/about-us"
                >
                  View more
                  <Image className="rightVector" src={rightVector} />
                </Link>
              </div>
              <div className="TiltedBox"></div>
              {!aboutphoto?.[0]?.data?.[0].image_about_us ? null : (
                <Image
                  className="AboutUsHomeImageDesktop w-100"
                  src={aboutUsImage}
                  fluid
                />
              )}
            </Row>
          </Row>
        )}
        { ourProductVisible === "true" && 
          <div>
            <div className="HomeProduct DefaultTitleHomePage">
              OUR PRODUCT
            </div>
            <Row>
              <HomeProduct />
            </Row>
            <div className=" d-flex w-100 justify-content-end align-items-center">
              <Link className="HomePageLinkButton" to="/product">
                View more
                <Image className="rightVector" src={rightVector} />
              </Link>
            </div>
          </div>
        }
      </Container>
    </div>
  );
}

export default Body;
