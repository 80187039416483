import React from "react";
import styles from "./ProductPage.module.css";
import ProductShowCategory from "../component/ProductShowCategory";
import ProductCategoryButton from "../component/ProductCategoryButton";
import ProductShowSubcategory from "../component/ProductShowSubcategory";
import rightVector from "../Assets/rightVector.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategoryList,
  getSubCategoryList,
  getProducts,
} from "../Store/Actions/product";
import { useRef, useEffect, useState, useCallback } from "react";
import { Col, Container, Row, Image, Button } from "react-bootstrap";
import Breadcrumb from "../component/Breadcrumb";
import axios from "axios";
import { baseURL } from "../Store/Actions";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

let sub_category_list = [];
let category_list = [];

function ProductPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { category, subcategory } = useParams();
  const [categoryLinkName, setCategoryLinkName] = useState(""); // item catLinkName
  const [linkName, setLinkName] = useState(""); // item breadccrumbs
  const [subLinkName, setSubLinkName] = useState(""); // item breadccrumbs sub
  const [isTabOverflowed, setIsTabOverflowed] = useState(false); // if CategoryTab is overflow to add arrows
  const [idCategory, setIdCategory] = useState(null); // id Category
  const [subCategory, setSubCategory] = useState(false); // true or false; jika ada subCat
  const [subData, setSubData] = useState(null); // data array
  const [showSubProduct, setShowSubProduct] = useState(null); // true or false
  const [idSubCategory, setIdSubCategory] = useState(null); // id subCategory
  const [idActiveButton, setIdActiveButton] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const handleProductList = (
    idCategory,
    subCategory,
    subData,
    showSubProduct,
    linkName
  ) => {
    // console.warn("nomor 4-a");
    setIdCategory(idCategory);
    setSubCategory(subCategory);
    setSubData(subData);
    setShowSubProduct(showSubProduct);
    setIdActiveButton(idCategory);
    setLinkName(linkName);
    setSubLinkName(null);
    // console.log("idCategory:", idCategory);
    // console.log("subCategory:", subCategory);
    // console.warn("linkname:", linkName);
    // console.log("showSubProduct:", showSubProduct);

    // validasi pencet button
    const firstLinkName = linkName;
    const secondLinkName = "";

    // console.warn("nomor 4-b");
    // console.log(`URL >${category} --- ${firstLinkName}`);
    // console.log(category !== firstLinkName);
    // console.log(`URL >${subcategory} --- ${secondLinkName}`);
    // console.log(subcategory !== secondLinkName);

    if (category !== firstLinkName || subcategory !== secondLinkName) {
      // console.warn("nomor 4-c");
      navigate(`/product/${linkName}`);
    }
  };
  const handleSubcategoryProductList = (
    showSubProduct,
    idSubCategory,
    subLinkName
  ) => {
    // console.warn("nomor 5-a");
    setShowSubProduct(showSubProduct);
    setIdSubCategory(idSubCategory);
    setSubLinkName(subLinkName);
    subLinkName = subLinkName;
    // console.log("showSubProduct:", showSubProduct);
    // console.log("showSubProduct:", idSubCategory);
    // console.warn("linkname:", linkName);
    // console.warn("sublinkname:", subLinkName);
    navigate(`/product/${category}/${subLinkName}`);
  };

  const showAllList = async () => {
    // console.clear();
    // console.warn("nomor 1");
    // await dispatch(getCategoryList());
    // dispatch(getSubCategoryList());
    category_list = await getCat();
    sub_category_list = await getSubCat();
    // console.warn(category_list);
    // console.warn(sub_category_list);
  };
  const getCat = async () => {
    // getAPI
    try {
      // console.warn("nomor 2");
      const response = await axios.get(baseURL + "/category");
      // console.log(response.data.result);
      return response.data.result;
    } catch (e) {
      // console.error(e.message);
    }
  };
  const getSubCat = async () => {
    // getAPI
    try {
      // console.warn("nomor 3");
      const response = await axios.get(baseURL + "/sub-category");
      // console.log(response.data.result);
      return response.data.result;
    } catch (e) {
      // console.error(e.message);
    }
  };

  // Horizontal Scroll Functions
  const categoryContainerRef = useRef(null);
  const [showLeftArrowButton, setShowLeftArrowButton] = useState(null);
  const [showRightArrowButton, setShowRightArrowButton] = useState(null);
  const scrollStep = 150;
  // MouseDrag
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);

  const checkScroll = () => {
    const container = categoryContainerRef.current;
    if (container) {
      setShowLeftArrowButton(container.scrollLeft > 0 && screenWidth <= 769);
      setShowRightArrowButton(
        container.scrollLeft < container.scrollWidth - container.clientWidth &&
          screenWidth <= 769
      );

      // console.log(
      //   "checkScroll is working",
      //   showLeftArrowButton,
      //   showRightArrowButton
      // );
    }
  };

  const handleScroll = (direction) => {
    const container = categoryContainerRef.current;
    if (container) {
      const scrollAmount = direction === "left" ? -scrollStep : scrollStep;
      container.scrollLeft += scrollAmount;
      checkScroll();
    }
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.clientX);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const container = categoryContainerRef.current;
    if (container) {
      const deltaX = e.clientX - startX;
      container.scrollLeft -= deltaX;
      setStartX(e.clientX);
      checkScroll();
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const checkIsTabOverflowed = () => {
    const elementTab = document.getElementById("elementTab");
    if (elementTab) {
      setIsTabOverflowed(elementTab.scrollWidth > elementTab.clientWidth);
    }
  };

  const setWindowWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    checkScroll();
    checkIsTabOverflowed();
    const container = categoryContainerRef.current;
    const onResize = () => {
      checkScroll();
      checkIsTabOverflowed();
    };
    if (container) {
      container.addEventListener("pointerdown", handleMouseDown);
      container.addEventListener("pointermove", handleMouseMove);
      container.addEventListener("pointerup", handleMouseUp);
      container.addEventListener("pointerleave", handleMouseUp);
    }
    window.addEventListener("resize", setWindowWidth, onResize);
    return () => {
      window.removeEventListener("resize", setWindowWidth, onResize);
      if (container) {
        container.removeEventListener("pointerdown", handleMouseDown);
        container.removeEventListener("pointermove", handleMouseMove);
        container.removeEventListener("pointerup", handleMouseUp);
        container.removeEventListener("pointerleave", handleMouseUp);
      }
    };
  }, []);

  const fetchCategoryList = (idx) => {
    // console.warn("nomor 4");
    if (category_list.length > 0) {
      const subCat = sub_category_list.filter(
        (subCategoryItem) =>
          subCategoryItem.id_category === category_list[idx].id
      );
      handleProductList(
        category_list[idx].id,
        subCat.length > 0,
        subCat,
        false,
        category_list[idx].link_name
      );
    }
  };
  const fetchSubCategoryList = (idx) => {
    // console.warn("nomor 5");
    if (sub_category_list.length > 0) {
      handleSubcategoryProductList(
        true,
        sub_category_list[idx].id,
        sub_category_list[idx].link_name
      );
    }
  };
  const ParamsChecker = async (category, subcategory) => {
    await showAllList();
    const categoryIndex = category_list.findIndex(
      (e) => e.link_name === category
    );

    if (categoryIndex !== -1) {
      await fetchCategoryList(categoryIndex);
      const subCategoryIndex = sub_category_list.findIndex(
        (e) => e.link_name === subcategory
      );
      if (subCategoryIndex !== -1) {
        // console.log(subcategory);
        // console.log(sub_category_list.some((e) => e.link_name === subcategory));
        // console.log(
        //   sub_category_list.findIndex((e) => e.link_name === subcategory)
        // );
        await fetchSubCategoryList(subCategoryIndex);
      } else {
        navigate(`/product/${category}`);
      }
    } else {
      navigate(`/product`);
      // console.warn(`second chance`);
    }
  };

  useEffect(() => {
    ParamsChecker(category, subcategory);
  }, [category, subcategory]);

  return (
    <Container className={styles.container} fluid>
      <Breadcrumb
        customValue={
          !(linkName && subLinkName) ? [linkName] : [linkName, subLinkName]
        }
      />
      <div className="DefaultTitlePage">OUR PRODUCT</div>

      <div className={styles.arrowNavigationContainer}>
        {screenWidth > 769 ? null : (
          <Button
            style={{
              visibility: showLeftArrowButton ? "visible" : "hidden",
            }}
            className={styles.leftArrowButton}
            onClick={() => handleScroll("left")}
          >
            <Image src={rightVector} />
          </Button>
        )}
        <Row
          id="elementTab"
          {...(screenWidth > 769
            ? { className: styles.categoryButtonRow }
            : {
                className: styles.categoryTab,
                ref: categoryContainerRef,
                onPointerDown: handleMouseDown,
                onPointerMove: handleMouseMove,
                onPointerUp: handleMouseUp,
                onPointerLeave: handleMouseUp,
              })}
        >
          {category_list.map((e) => {
            const subCat = sub_category_list.filter(
              (subCategoryItem) => subCategoryItem.id_category === e.id
            );
            return (
              <Col
                style={{ padding: 0, margin: 0 }}
                lg={screenWidth > 769 ? 3 : undefined}
                sm={screenWidth > 769 ? 4 : undefined}
                // md={screenWidth > 769 ? 4 : undefined}
                className={
                  screenWidth > 769
                    ? "mt-2 d-flex justify-content-center align-items-center"
                    : styles.productCategoryButtonContainer
                }
              >
                <ProductCategoryButton
                  key={e.id}
                  name={e.name_category}
                  onIdClick={handleProductList}
                  id={e.id}
                  sub={subCat.length > 0}
                  subData={subCat}
                  active={idActiveButton === e.id}
                  linkName={e.link_name}
                />
              </Col>
            );
          })}
        </Row>
        {screenWidth > 769 ? null : (
          <Button
            style={{
              visibility: showRightArrowButton ? "visible" : "hidden",
            }}
            className={styles.rightArrowButton}
            onClick={() => handleScroll("right")}
          >
            <Image src={rightVector} />
          </Button>
        )}
      </div>
      <Row className={styles.productContainerList}>
        {subCategory ? (
          showSubProduct ? (
            <ProductShowCategory id={idSubCategory} sub={subCategory} />
          ) : (
            <ProductShowSubcategory
              data={subData}
              onClick={handleSubcategoryProductList}
            />
          )
        ) : (
          <ProductShowCategory id={idCategory} sub={subCategory} />
        )}
      </Row>
    </Container>
  );
}
export default ProductPage;
