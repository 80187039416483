import React from "react";
import styles from "./HomeProduct.module.css";
import { useRef, useEffect, useState } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategoryList,
  getFavouriteProducts,
} from "../Store/Actions/product";
import { useCallback } from "react";
import ProductCategoryButton from "../component/ProductCategoryButton";
import ProductNotFound from "./ProductNotFound";
import HomeProductContainer from "./HomeProductContainer";
import rightVector from "../Assets/rightVector.svg";

function HomeProduct() {
  const dispatch = useDispatch();
  const [idCategory, setIdCategory] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [idActiveButton, setIdActiveButton] = useState(null);
  // const [firstItem, setFirstItem] = useState(true);
  const isFirstItem = useRef(true);
  const setWindowWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  const { category_list, favourite_products } = useSelector(
    (state) => state.product
  );
  const showCategoryList = async () => {
    try {
      dispatch(getCategoryList());
    } catch (e) {
      // console.log(e);
    }
  };
  const showFavouriteProducts = async (idCat) => {
    try {
      dispatch(getFavouriteProducts(idCat));
    } catch (e) {
      // console.log(e);
    }
  };

  const handleProductList = useCallback((idCategory) => {
    setIdCategory(idCategory);
    showFavouriteProducts(idCategory);
    setIdActiveButton(idCategory);
    // console.log("idCategory:", idCategory);
  }, []);

  // Horizontal Scroll Functions
  const categoryContainerRef = useRef(null);
  const [showLeftArrowButton, setShowLeftArrowButton] = useState(null);
  const [showRightArrowButton, setShowRightArrowButton] = useState(null);
  const scrollStep = 150;
  // MouseDrag
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);

  const checkScroll = () => {
    const container = categoryContainerRef.current;
    if (container) {
      setShowLeftArrowButton(container.scrollLeft > 0 && screenWidth <= 768);
      setShowRightArrowButton(
        container.scrollLeft < container.scrollWidth - container.clientWidth &&
          screenWidth <= 768
      );

      // console.log(
      //   "checkScroll is working",
      //   showLeftArrowButton,
      //   showRightArrowButton
      // );
    }
  };

  const handleScroll = (direction) => {
    const container = categoryContainerRef.current;
    if (container) {
      const scrollAmount = direction === "left" ? -scrollStep : scrollStep;
      container.scrollLeft += scrollAmount;
      checkScroll();
    }
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.clientX);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const container = categoryContainerRef.current;
    if (container) {
      const deltaX = e.clientX - startX;
      container.scrollLeft -= deltaX;
      setStartX(e.clientX);
      checkScroll();
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  // console.log(showLeftArrowButton, showRightArrowButton, screenWidth <= 768);
  useEffect(() => {
    showCategoryList();
    checkScroll();
    const container = categoryContainerRef.current;

    if (container) {
      container.addEventListener("pointerdown", handleMouseDown);
      container.addEventListener("pointermove", handleMouseMove);
      container.addEventListener("pointerup", handleMouseUp);
      container.addEventListener("pointerleave", handleMouseUp);
    }

    const onResize = () => {
      checkScroll();
    };
    window.addEventListener("resize", setWindowWidth, onResize);
    return () => {
      window.removeEventListener("resize", setWindowWidth, onResize);
      if (container) {
        container.removeEventListener("pointerdown", handleMouseDown);
        container.removeEventListener("pointermove", handleMouseMove);
        container.removeEventListener("pointerup", handleMouseUp);
        container.removeEventListener("pointerleave", handleMouseUp);
      }
    };
  }, []);
  useEffect(() => {
    if (category_list.length > 0 && isFirstItem.current) {
      isFirstItem.current = false;
      handleProductList(category_list[0].id);
    }
  }, [category_list]);

  return (
    <Container>
      <div className={styles.arrowNavigationContainer}>
        {screenWidth > 769 ? null : (
          <Button
            style={{ visibility: showLeftArrowButton ? "visible" : "hidden" }}
            className={styles.leftArrowButton}
            onClick={() => handleScroll("left")}
          >
            <Image src={rightVector} />
          </Button>
        )}
        <Row
          {...(screenWidth > 768
            ? { className: "d-flex align-items-center w-100" }
            : {
                className: styles.categoryHomeTab,
                ref: categoryContainerRef,
                onPointerDown: handleMouseDown,
                onPointerMove: handleMouseMove,
                onPointerUp: handleMouseUp,
                onPointerLeave: handleMouseUp,
              })}
        >
          {category_list.map((e, index) => (
            <Col
              style={{ padding: 0, margin: 0 }}
              lg={screenWidth > 769 ? 3 : undefined}
              sm={screenWidth > 769 ? 4 : undefined}
              className={
                screenWidth > 768
                  ? "mt-2 d-flex justify-content-center align-items-center "
                  : styles.homeproductCategoryButtonContainer
              }
            >
              <ProductCategoryButton
                key={e.id}
                onIdClick={handleProductList}
                id={e.id}
                name={e.name_category}
                active={idActiveButton === e.id}
              />
            </Col>
          ))}
        </Row>
        {screenWidth > 769 ? null : (
          <Button
            style={{ visibility: showRightArrowButton ? "visible" : "hidden" }}
            className={styles.rightArrowButton}
            onClick={() => handleScroll("right")}
          >
            <Image src={rightVector} />
          </Button>
        )}
      </div>
      {favourite_products?.[0]?.data.length > 0 ? (
        <Row className={styles.homeProductContainerList}>
          {favourite_products?.[0]?.data.map((e) => (
            <Col
              className="homeProductShowCategoryCol"
              key={e.id}
              xs={12}
              md={4}
            >
              <HomeProductContainer
                name={e.name_product}
                description={e.description}
                image={e.image_product}
              />
            </Col>
          ))}
        </Row>
      ) : (
        <ProductNotFound />
      )}
    </Container>
  );
}
export default HomeProduct;
