const initState = {
  socialmedia: [],
  // socialmedia_email: [],
  about: [],
  aboutphoto: [],
  contactinfo: [],
  homephotos: [],
};

const company = (state = initState, action) => {
  switch (action.type) {
    case "SET_ABOUT":
      const about = {
        ...state,
        about: action.about,
      };
      // console.log(action)
      return about;

    case "SET_ABOUTPHOTO":
      const aboutphoto = {
        ...state,
        aboutphoto: action.aboutphoto,
      };
      // console.log(action)
      return aboutphoto;

    case "SET_SOCIALMEDIA":
      const socialmedia = {
        ...state,
        socialmedia: action.socialmedia,
      };
      // console.log(action)
      return socialmedia;

    // case "SET_SOCIALMEDIA_EMAIL":
    //   const socialmedia_email = {
    //     ...state,
    //     socialmedia_email: action.socialmedia_email
    //   }
    //   console.log(action)
    //   return socialmedia_email

    case "SET_CONTACTINFO":
      const contactinfo = {
        ...state,
        contactinfo: action.contactinfo,
      };
      // console.log(action);
      return contactinfo;

    case "SET_HOMEPHOTOS":
      const homephotos = {
        ...state,
        homephotos: action.homephotos,
      };
      // console.log(action);
      return homephotos;

    default:
      return state;
  }
};

export default company;
