const initState = {
  products: [],
  category: [],
  sub_category: [],
  category_list: [],
  sub_category_list: [],
  photos: [],
  favourite_products: [],
};
const product = (state = initState, action) => {
  switch (action.type) {
    case "SET_PRODUCTS":
      const products = {
        ...state,
        products: action.products,
      };
      // console.log(action)
      return products;

    case "SET_FAVOURITE_PRODUCTS":
      const favourite_products = {
        ...state,
        favourite_products: action.favourite_products,
      };
      // console.log(action)
      return favourite_products;

    case "SET_PRODUCT":
      const product = {
        ...state,
        product: action.product,
      };
      // console.log(action)
      return product;

    case "SET_PHOTOS":
      const photos = {
        ...state,
        photos: action.photos,
      };
      // console.log(action);
      return photos;

    case "SET_CATEGORY":
      const category = {
        ...state,
        category: action.category,
      };
      // console.log(action);
      return category;

    case "SET_CATEGORY_LIST":
      const category_list = {
        ...state,
        category_list: action.category_list,
      };
      // console.log(action);
      return category_list;

    case "SET_SUB_CATEGORY":
      const sub_category = {
        ...state,
        sub_category: action.sub_category,
      };
      // console.log(action);
      return sub_category;

    case "SET_SUB_CATEGORY_LIST":
      const sub_category_list = {
        ...state,
        sub_category_list: action.sub_category_list,
      };
      // console.log(action);
      return sub_category_list;

    default:
      return state;
  }
};

export default product;
