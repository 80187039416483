import React from 'react'
import "./AlertSuccess.css"

function AlertSuccess() {
  return (
    <div className='AlertContainer'>
        <div className='AlertText'>
        Thank you for subscribing! We will send our news and events to your email.
        </div>
    </div>
  )
}

export default AlertSuccess
