import React from "react";
import styles from "./PageNotFound.module.css"
import IconNotFound from "../../Assets/IconNotFound.png";



function PageNotFound() {
    return (
        <div className={styles.notFoundPage}>
            <div className={styles.IconNotFound}>
                <img src={IconNotFound} />
            </div>
            <div className={styles.bigText}>
                Oops! Page Not Found.
            </div>
            <div className={styles.smallText}>
                Mohon maaf, halaman yang Anda cari tidak dapat ditemukan.
            </div>
        </div>
    )
}
export default PageNotFound;