import React, { useState, useEffect } from "react";
// css from App.css
import { Link } from "react-router-dom";
import logo from "./Assets/Logo.jpg";
import loginLogo from "../src/Assets/loginLogo.svg";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import ConfirmLogout from "./component/ConfirmLogout.js";
import { useDispatch } from "react-redux";
import {
  Modal,
  Form,
  Button,
  Col,
  Row,
  Image,
  Container,
} from "react-bootstrap";
import { BiLowVision, BiShow } from "react-icons/bi";
import { datauser, loginUser, validateEmail } from "./Store/Actions/user";
import { logoutUser } from "./Store/Actions/user";
import { UserForgotPassword } from "./Store/Actions/user";
import styles from "./Pages/Login.module.css";
import verifyLaterStyle from "./Pages/SignUp.module.css";
import OTPStyles from "./Pages/PageOTP.module.css";
import popUpStyles from "./component/PopUp.module.css";
import errorIcon from "./Assets/ErrorIcon.png";
import validator from "validator";
import LoadingSpinner from "./component/LoadingSpinner";
import OTPemail from "./Assets/OTPemail.jpg";
import OtpInput from "react-otp-input";
import SuccessIcon from "./Assets/SuccessIcon.png";
import { sendOTP } from "./Store/Actions/user";
import HomeShowCanvas from "./component/HomeShowCanvas";
import { axiosGet } from "./Store/Actions";
import { useLocation } from "react-router-dom";
import logoNavbar from "./Assets/logoNavbar.png";

// const navLinks = [
//   { to: "/product", text: "Product" },
//   { to: "/about-us", text: "About Us" },
//   { to: "/contact-us", text: "Contact Us" },
// ];

const NavigationBar = ({ 
  username,
  productVisible,
  // aboutVisible,
  contactVisible,
}) => {

  let navLinks = [
    { visible: productVisible, to: "/product", text: "Product" },
    { visible: "true", to: "/about-us", text: "About Us" },
    { visible: contactVisible, to: "/contact-us", text: "Contact Us" },
  ];

  const [fullName, setFullName] = useState("");
  const [tokenlogin, setTokenLogin] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [email, setEmail] = useState(""); // Add state for email input
  const [password, setPassword] = useState(""); // Add state for password input
  const [passwordShown, setPasswordShown] = useState(false);
  const [show, setShow] = useState(false); // State for password show/hide in modal
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");
  const [showForgotPasswordSuccess, setShowForgotPasswordSuccess] =
    useState(false);
  const [isLoadingForgotPassword, setIsLoadingForgotPassword] = useState(false);
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [showForgotPasswordErrorPopUp, setforgotPasswordErrorPopUp] =
    useState(false);
  const [showVerifyLater, setShowVerifyLater] = useState(false);

  useEffect(() => {
    const id = localStorage.getItem("id");
    if (id){
      getDataUser(id);
    }
    const storedFullName = localStorage.getItem("fullName");
    const loginToken = localStorage.getItem("token");

    if (storedFullName && loginToken) {
      setFullName(storedFullName);
      setTokenLogin(loginToken);
    }
  }, [username]);

  const [showSuccess, setShowSuccess] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [otp, setOtp] = useState("");
  const [OTPError, setOTPError] = useState(null);

  const handleLogout = () => {
    dispatch(logoutUser());
    window.location.reload();
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("fullname");
    localStorage.removeItem("fullName");
    localStorage.removeItem("email");
    localStorage.removeItem("phone");
    localStorage.removeItem("idRole")
    window.location.href = "/";
  };

  const handleOtpSubmit = async () => {
    try {
      const response = await validateEmail(otp);
      if (response && response.status === 200) {
        // console.log("OTP verification successful");
        return true;
      }
    } catch (e) {
      return false;
    }
  };

  const OTPValidation = () => {
    //isEmpty
    if (otp.length === 0) {
      return [false, setOTPError("OTP must be filled.")];
    }
    if (otp.length < 6) {
      return [false, setOTPError("All OTP field must be filled.")];
    }
    return [true, setOTPError(null)];
  };

  const a = () => {
    if (showOTP) {
      setShowOTP(false);
    } else {
      setShowOTP(true);
    }
  };

  const handleClick = () => {
    // useState({ showAlert: true });
    setShowAlert(true);
  };

  // ~LOGIN~ START

  const [errorMessage, setErrorMessage] = useState({
    emailError: "",
    passwordError: "",
    forgotPassword: "",
  });

  const [errorPopUp, setErrorPopUp] = useState(false);

  //Open or Close Popup
  const handleLoginPopUp = () => {
    cleanErrorLoginMessage();
    if (!showLogin) {
      setEmail("");
      setPassword("");
      setShowLogin(true);
    } else {
      setShowLogin(false);
    }
  };

  const cleanErrorLoginMessage = () => {
    setErrorMessage((prevError) => ({
      ...prevError,
      emailError: "",
      passwordError: "",
    }));
  };

  const cleanErrorForgotPasswordMessage = () => {
    setErrorMessage((prevError) => ({
      ...prevError,
      forgotPassword: "",
    }));
  };

  //// -=Validation=- START

  const emailValidation = () => {
    //Email is Empty
    if (email.trim().length === 0) {
      return [
        setErrorMessage((prevError) => ({
          ...prevError,
          emailError: "Email must be filled.",
        })),
        false,
      ];
    }

    //isNotEmail
    if (!validator.isEmail(email.trim())) {
      return [
        setErrorMessage((prevError) => ({
          ...prevError,
          emailError: "Input Email in this field.",
        })),
        false,
      ];
    }
    return [
      setErrorMessage((prevError) => ({ ...prevError, emailError: "" })),
      true,
    ];
  };

  //Password is Empty
  const passwordIsEmpty = () => {
    if (password.length === 0) {
      return [
        setErrorMessage((prevError) => ({
          ...prevError,
          passwordError: "Password must be filled.",
        })),
        false,
      ];
    }
    return [
      setErrorMessage((prevError) => ({ ...prevError, passwordError: "" })),
      true,
    ];
  };

  const validateAll = async () => {
    const emailIsValid = emailValidation()[1];
    const passwordIsValid = passwordIsEmpty()[1];
    if (emailIsValid && passwordIsValid) {
      setIsLoadingLogin(true);
      await handleLogin();
      setIsLoadingLogin(false);
    }
  };

  //// -=Validation=- END
  const setFullNameQ = (fullName) => {
    // console.log("Full Name:", fullName);
    localStorage.setItem("fullName", fullName);
  };

  const setEmailQ = (email) => {
    // console.log("Email:", email);
    localStorage.setItem("email", email);
  };

  const setPhone = (phone) => {
    // console.log("Phone:", phone);
    localStorage.setItem("phone", phone);
  };

  const userId = localStorage.getItem("id");
  //LoginSubmition
  const handleLogin = async () => {
    // console.clear();
    // console.clear();
    // console.log(email);
    // console.log(password);
    var response = await dispatch(loginUser({ email, password }));
    // console.log("Response:", response);
    // console.log(response[1]);
    // console.log(response[0]);
    if (response[1].status === 200) {
      setIsLoggedIn(true);
      // var responseQ = await getDataUser(response[1].result[0].id)
      // console.log(responseQ)
      // window.location.reload();
      // var responseQ = await dispatch(datauser(response[1].result[0].id, setFullNameQ, setEmailQ, setPhone))
      const data = await axiosGet(
        `user/iduser?id_user=${response[1].result[0].id}`
      );
      localStorage.setItem("id", response[1].result[0].id);
      localStorage.setItem("token", response[1].result[0].token.token);
      // console.log(data);
      setFullNameQ(data.fullname);
      setEmailQ(data.email);
      setPhone(data.phone);
      setFullName(data.fullname);
      setTokenLogin(response[1].result[0].token.token);
      setShowLogin(false);
      window.location.href = "/";
      // window.location.reload(); 
    } else {
      // console.log("1");
      if (response[1] === "Email and Password Incorrect") {
        // console.log("2");
        setErrorPopUp(true);
      } else {
        // console.log("3");
        setShowVerifyLater(true);
      }
    }
  };
  // ~LOGIN~ END

  const getDataUser = async (id) => {
    try {
      // console.warn("fetching user id");
      dispatch(datauser(id, setFullNameQ, setEmailQ, setPhone));
    } catch (e) {
      // console.warn(e);
    }
  };

  // ~FORGOT PASSWORD~ START

  const forgotPasswordValidation = () => {
    //isEmpty
    if (forgotEmail.trim().length === 0) {
      return [
        setErrorMessage((prevError) => ({
          ...prevError,
          forgotPassword: "Email must be filled.",
        })),
        false,
      ];
    }
    //isEmail
    if (!validator.isEmail(forgotEmail.trim())) {
      return [
        setErrorMessage((prevError) => ({
          ...prevError,
          forgotPassword: "Input Email in this field.",
        })),
        false,
      ];
    }
    return [
      setErrorMessage((prevError) => ({
        ...prevError,
        forgotPassword: "",
      })),
      true,
    ];
  };

  // ~FORGOT PASSWORD~ END

  const handleLoginClose = () => {
    // useState({showLogin: false})
    setShowLogin(false);
    // setShowForgotPasswordModal(false);
  };
  const handleOpenForgotPasswordModal = () => {
    setForgotEmail("");
    setShowLogin(false);
    setShowForgotPasswordModal(true);
  };

  const handleCloseForgotPasswordModal = () => {
    setForgotEmail("");
    cleanErrorForgotPasswordMessage();
    setShowForgotPasswordModal(false);
  };
  const handleShow = () => {
    setPasswordShown(!passwordShown);
  };
  const dispatch = useDispatch();

  const handleScrollToTopButton = () => {
    // Go to Top of Page
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // setIsNavActive(null)
  };

  const handleClickForgotPassword = async () => {
    try {
      const response = await UserForgotPassword(forgotEmail);
      if (response.status === 200) {
        return true;
      }
    } catch (e) {
      return false;
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const genNewOTP = async () => {
    dispatch(await sendOTP({ email: email }));
  };

  const [showCanvas, setCanvas] = useState(false);

  const location = useLocation();

  return (
    <div>
      <Navbar expand="lg" className="p-0 Nav" collapseOnSelect>
        {/* Kasih margin bottom di navbar  */}
        <div className="NavbarLeftResponsive">
          <HomeShowCanvas 
            productVisible={navLinks[0].visible}
            contactVisible={navLinks[2].visible}
          />
          <Navbar.Brand
            as={Link}
            to="/"
            onClick={handleScrollToTopButton}
            style={{ padding: 0, margin: 0 }}
            className="NavBrand"
          >
            {/* <img className="LogoKiri" src={logo} alt="HomeButton" /> */}
            <img className="LogoKiri" src={logoNavbar} alt="HomeButton" />
          </Navbar.Brand>
        </div>
        <Navbar id="responsive-navbar-nav">
          <Nav>
            {navLinks.map((navLink, index) => ( 
              navLink.visible === "true" && 
              <Nav.Link
                key={index}
                className="NavLink NavLinkRes"
                as={Link}
                to={navLink.to}
                style={
                  // location.pathname === navLink.to
                  location.pathname.startsWith(navLink.to)
                    ? { boxShadow: "0 0.125rem 0 white" }
                    : {}
                }
              >
                <center>{navLink.text}</center>
              </Nav.Link>
            ))}

            {fullName && tokenlogin ? (
              <NavDropdown
                className="LoginDropDown NavLink"
                align="end"
                // id="collasible-nav-dropdown"
                title={
                  <div className="d-flex justify-content-center align-items-center">
                    {fullName.length !== 0 ? (
                      <div className="NavbarUserName">{fullName}</div>
                    ) : (
                      <div className="NavbarUserName">{username}</div>
                    )}
                    <FontAwesomeIcon
                      className="ArrowVector"
                      icon={faAngleDown}
                      size="sm"
                      style={{ paddingLeft: "0.25rem" }}
                    />
                  </div>
                }
              >
                <NavDropdown.Item as={Link} to="/edit-profile">
                  <center className="LogoutDropDownBtn">Edit Profile</center>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => setShowLogoutModal(true)}>
                  <center className="LogoutDropDownBtn">Logout</center>
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <Nav.Link
                className="LoginDropDown NavLink d-flex justify-content-center align-items-center"
                onClick={() => handleLoginPopUp()}
              >
                <>
                  Login
                  <img className="LoginLogo" src={loginLogo} alt="LoginLogo" />
                  <FontAwesomeIcon
                    className="ArrowVector"
                    icon={faAngleDown}
                    size="sm"
                    style={{ paddingLeft: "0.25rem" }}
                  />
                </>
              </Nav.Link>
            )}

            {/* modal login */}
            <Modal
              centered
              className="LoginModal d-flex justify-content-center"
              show={showLogin}
              onHide={handleLoginPopUp}
            >
              <Modal.Header closeButton className={styles.TopContainer}>
                <Modal.Title className={styles.LoginTitle}>Login</Modal.Title>
              </Modal.Header>
              <div className="LoginModalContent">
                <Modal.Body className={styles.Body}>
                  <Form className={styles.Container}>
                    <Form.Group
                      className={`${styles.EmailContainer}`}
                      controlId="email"
                    >
                      <Form.Label className={styles.email}>Email</Form.Label>
                      <Form.Control
                        className={styles.EmailBox}
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {errorMessage.emailError.length != 0 ? (
                        <div style={{ color: "#BE1620" }}>
                          {errorMessage.emailError}
                        </div>
                      ) : null}
                    </Form.Group>
                    <Form.Group className={styles.PasswordContainer}>
                      <Form.Label className={styles.password}>
                        Password
                      </Form.Label>
                      <div className={styles.PasswordSubContainer}>
                        {/* <div className="password-input-container"> */}
                        <Form.Control
                          className={styles.PasswordBox}
                          type={passwordShown ? "text" : "password"}
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value.trim())}
                        />
                        {/* </div> */}
                        {!passwordShown ? (
                          <BiShow
                            className={styles.eyeicon}
                            onClick={handleShow}
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          <BiLowVision
                            className={styles.eyeicon}
                            onClick={handleShow}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </div>
                      {errorMessage.passwordError.length != 0 ? (
                        <div style={{ color: "#BE1620" }}>
                          {errorMessage.passwordError}
                        </div>
                      ) : null}
                    </Form.Group>

                    <div className={styles.ForgotPassword}>
                      <Link
                        onClick={handleOpenForgotPasswordModal}
                        className={styles.ForgotPasswordLink}
                      >
                        Forgot your password?
                      </Link>
                    </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer
                  className="d-flex justify-content-center"
                  style={{ padding: 0 }}
                >
                  <div
                    className={`d-flex justify-content-center ${styles.LoginBody}`}
                  >
                    {!isLoadingLogin ? (
                      <Button
                        variant="primary"
                        onClick={() => {
                          validateAll();
                        }}
                        className={styles.login}
                      >
                        Login
                      </Button>
                    ) : (
                      <LoadingSpinner />
                    )}
                  </div>
                  <div className={styles.SignUp}>
                    Don't have an account?{" "}
                    <Link
                      to="/sign-up"
                      onClick={() => {
                        handleLoginPopUp();
                      }}
                      className={styles.SignUpLink}
                    >
                      Sign Up Now
                    </Link>
                  </div>
                </Modal.Footer>
              </div>
            </Modal>
            <div className="PopUpLogout">
              <ConfirmLogout show={showAlert} onClose={handleLogout} />
            </div>
          </Nav>
        </Navbar>
      </Navbar>

      {/* modals error login */}
      <Modal
        className={styles.PopUpErrorLogin}
        centered
        show={errorPopUp}
        onHide={() => setErrorPopUp(false)}
      >
        <Modal.Body
          className={`${styles.PopUpErrorBody} ${styles.PopUpErrorBodyLogin}`}
        >
          <Modal.Header style={{ padding: 0 }}>
            <img src={errorIcon} />
          </Modal.Header>
          <div className={styles.PopUpErrorMessageContainer}>
            <Modal.Footer className={styles.PopUpLoginErrorFooterWithImage}>
              Incorrect Password or Email<br />please check again.
            </Modal.Footer>
          </div>
        </Modal.Body>
      </Modal>
      {/* modals confirm logout */}
      <Modal
        className="d-flex justify-content-center"
        show={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
      >
        <Modal.Header className="PopUpLogoutTopHome">
          <Modal.Title className={styles.LoginTitle}>Logout</Modal.Title>
        </Modal.Header>
        <div className="d-flex flex-column justify-content-center align-items-center PopUpLogoutContent">
          <Modal.Body className="ForgotPasswordMessage">
            Are you sure you want to logout?
          </Modal.Body>
          <Modal.Footer style={{ padding: 0 }} className="PopUpLogoutHome">
            <Button onClick={handleLogout} className="PopUpLogoutButton">
              Logout
            </Button>
            <Button
              className="PopUpLogoutButton CancelButton"
              onClick={() => setShowLogoutModal(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
      {/* modals forgot password */}
      <Modal
        show={showForgotPasswordModal}
        onHide={handleCloseForgotPasswordModal}
        centered
        className="ForgotPasswordPopUp"
      >
        <Modal.Header closeButton className="ForgotPasswordTopContainer">
          <Modal.Title className="ForgotPasswordTitle">
            Forgot Password
          </Modal.Title>
        </Modal.Header>
        <div className="ForgotPasswordContainer">
          <Modal.Body className="ForgotPasswordContent">
            <div className="ForgotPasswordMessageContainer">
              <p style={{ margin: 0 }} className="ForgotPasswordMessage">
                Please enter your email, we will send your password to your
                email
              </p>
            </div>

            <Form.Group className="ForgotPasswordFormGroup" controlId="email">
              <Form.Label className={styles.email}>Email</Form.Label>
              <Form.Control
                className={styles.EmailBox}
                type="email"
                placeholder="Enter email"
                value={forgotEmail}
                onChange={(e) => setForgotEmail(e.target.value)}
              />
              {errorMessage.forgotPassword.length === 0 ? null : (
                <div style={{ color: "#BE1620" }}>
                  {errorMessage.forgotPassword}
                </div>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer style={{ padding: 0 }} className="FooterForgotPassword">
            {!isLoadingForgotPassword ? (
              <Button
                variant="primary"
                onClick={async () => {
                  const forgotPasswordValid = forgotPasswordValidation()[1];
                  if (forgotPasswordValid) {
                    setIsLoadingForgotPassword(true);
                    // console.log("is true", isLoadingForgotPassword);
                    const response = await handleClickForgotPassword();
                    if (response) {
                      setIsLoadingForgotPassword(false);
                      setShowForgotPasswordModal(false);
                      setShowForgotPasswordSuccess(true);
                    } else {
                      setIsLoadingForgotPassword(false);
                      setforgotPasswordErrorPopUp(true);
                    }
                  }
                }}
                className={styles.login}
              >
                Send
              </Button>
            ) : (
              <LoadingSpinner />
            )}
          </Modal.Footer>{" "}
        </div>
      </Modal>
      {/* modal forgotpass succses */}
      <Modal
        className="d-flex justify-content-center ForgotPasswordSuccessModal"
        show={showForgotPasswordSuccess}
        onHide={() => setShowForgotPasswordSuccess(false)}
      >
        <Modal.Header className="ForgotPasswordSuccessTopContainer">
          <Modal.Title className="ForgotPasswordSucessTitle">
            Email Sent
          </Modal.Title>
        </Modal.Header>
        <div className="ForgotPasswordSuccessContent">
          <Modal.Body>
            <div className="NotifInfo">
              We have sent your password to your email
              <div style={{ color: "#BE1620" }}>{forgotEmail}</div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className={`ForgotPasswordSuccessButton ${styles.login}`}
              variant="primary"
              onClick={() => setShowForgotPasswordSuccess(false)}
            >
              OK
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
      {/* modal forgot password gagal */}
      <Modal
        className={styles.PopUpErrorLogin}
        centered
        show={showForgotPasswordErrorPopUp}
        onHide={() => setforgotPasswordErrorPopUp(false)}
      >
        <Modal.Body className={styles.PopUpErrorBody}>
          <Modal.Footer className={styles.PopUpLoginErrorFooter}>
            Email Not Found
          </Modal.Footer>
        </Modal.Body>
      </Modal>
      {/* modal verify later login */}
      <Modal
        className="ModalVerifyLater"
        show={showVerifyLater}
        onHide={() => setShowVerifyLater(false)}
        centered
      >
        <Col className={`${verifyLaterStyle.ErrorPopUp} p-4`}>
          <Modal.Header>
            <img
              className={verifyLaterStyle.ErrorImage}
              src={errorIcon}
              alt=""
            />
          </Modal.Header>
          <Modal.Body>Please verify your email before loging in.</Modal.Body>
          <Modal.Footer>
            <Row className="d-flex justify-content-center">
              <Button
                className={verifyLaterStyle.VerifyLaterButton}
                onClick={() => {
                  genNewOTP();
                  setShowVerifyLater(false);
                  setShowLogin(false);
                  setShowOTP(true);
                }}
              >
                Verify Account
              </Button>
            </Row>
          </Modal.Footer>
        </Col>
      </Modal>
      {/* Modal OTP */}
      <Modal show={showOTP} centered className="d-flex justify-content-center">
        <div className={OTPStyles.OuterOTPContainer}>
          <Modal.Header style={{ width: "100%", padding: 0 }}>
            <Modal.Title className={OTPStyles.OTPtitle}>
              Verify Email
            </Modal.Title>
          </Modal.Header>
          <div className={OTPStyles.PageOTPContainer}>
            <Image className={OTPStyles.OTPemail} src={OTPemail} />
            <Modal.Body style={{ padding: 0 }}>
              <div className={OTPStyles.PleaseEnter}>
                Please enter the 6 digit code sent to your email
                <div>{email}</div>
              </div>
              <div className={OTPStyles.OTPinput}>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span style={{ width: "10px" }}></span>}
                  renderInput={(props) => <input {...props} />}
                  inputStyle={OTPStyles.OTPField}
                />
              </div>
              {OTPError === null ? null : (
                <center className="m-3" style={{ color: "#BE1620" }}>
                  {OTPError}
                </center>
              )}
            </Modal.Body>
            <Modal.Footer className={OTPStyles.ButtonContainer}>
              <Button
                className={OTPStyles.OTPbutton}
                onClick={async () => {
                  const OTPValid = OTPValidation()[0];
                  if (OTPValid) {
                    const response = await handleOtpSubmit();
                    // console.log(response);
                    if (response) {
                      setShowOTP(false);
                      setShowSuccess(true);
                    } else {
                      setOtp("");
                      setOTPError("Please insert the correct OTP.");
                    }
                  }
                }}
              >
                Sign Up
              </Button>
            </Modal.Footer>
          </div>
        </div>
      </Modal>
      {/* modal success */}
      <Modal
        className={popUpStyles.ModalSuccess}
        style={{ padding: 0 }}
        centered
        show={showSuccess}
        onHide={async () => {
          await handleLogin();
        }}
      >
        <Modal.Header
          className={popUpStyles.ModalHeaderSuccess}
          style={{ padding: 0 }}
        >
          <h1 className={popUpStyles.ModalHeaderTitleSuccess}>Verify Email</h1>
        </Modal.Header>
        <Container className={popUpStyles.ModalBodyContainerSuccess}>
          <Modal.Body
            className={popUpStyles.ModalBodyContentSuccess}
            style={{ padding: 0 }}
          >
            <img
              className={popUpStyles.ModalBodyContentImageSuccess}
              src={SuccessIcon}
            />
            <div style={{ padding: 0 }}>
              You have successfully verified the account.
            </div>
          </Modal.Body>
          <Modal.Footer
            style={{ padding: 0 }}
            className={popUpStyles.ModalFooterSuccess}
          >
            <Button
              onClick={async () => {
                await handleLogin();
              }}
              className={popUpStyles.ModalFooterButtonSuccess}
            >
              OK
            </Button>
          </Modal.Footer>
        </Container>
      </Modal>
    </div>
  );
};

export default NavigationBar;
