import React, { useEffect, useState, useRef, useId } from "react";
import "./EditProfile.css";
import { useDispatch } from "react-redux";
import {
  userphoto,
  adduserphoto,
  deletephoto,
  updateUserPhoto,
} from "../Store/Actions/user";
import { useNavigate, Link } from "react-router-dom";
import ChangePassword from "./ChangePassword";
import { Col, Container, Row, Button, Form } from "react-bootstrap";
import { Avatar } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import { BiPencil } from "react-icons/bi";
import { changeuserdata } from "../Store/Actions/user";
import validator from "validator";
import { config } from "@fortawesome/fontawesome-svg-core";
import LoadingSpinner from "../component/LoadingSpinner";
import Breadcrumb from "../component/Breadcrumb";
import { baseURL } from "../Store/Actions";

const baseUrl = baseURL;

function EditProfile({ updateNavbarFullName }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isChanged, setIsChanged] = useState(false);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [oriFullName, setOriFullName] = useState("");
  const [oriEmail, setOriEmail] = useState("");
  const [oriPhone, setOriPhone] = useState("");
  const [userPhoto, setUserPhoto] = useState("");
  const photoChanged = useRef(false);
  const [userPhotoPath, setUserPhotoPath] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [tesphoto, setTesPhoto] = useState();
  const fileInputRef = useRef(null);
  const [showSuccessEdit, setShowSuccessEdit] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoadingEditProfile, setIsLoadingEditProfile] = useState(false);

  const [fullNameError, setFullNameError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [emailError, setEmailError] = useState(null);

  const maxFullName = 30;
  const maxEmail = 30;
  const minPhone = 10;
  const maxPhone = 15;

  // validate fullname
  const fullNameValidation = () => {
    //isEmpty
    if (fullName.trim().length === 0) {
      return [setFullNameError("Full Name must be filled."), false];
    }
    //maxChar
    if (fullName.trim().length > maxFullName) {
      return [
        setFullNameError(
          "Full Name must be no more than " + maxFullName + " characters."
        ),
        false,
      ];
    }
    return [setFullNameError(null), true];
  };

  //email validation
  const emailValidation = () => {
    //isEmpty
    if (email.trim().length === 0) {
      return [setEmailError("Email must be filled."), false];
    }
    //maxChar
    if (email.trim().length > maxEmail) {
      return [
        setEmailError("Email must be no more than " + maxEmail + "."),
        false,
      ];
    }
    //isNotEmail
    if (!validator.isEmail(email.trim())) {
      return [setEmailError("Input Email in this field."), false];
    }
    return [setEmailError(null), true];
  };

  //phonenumber validation
  const phoneNumberValidation = () => {
    //isEmpty
    if (phone.length === 0) {
      return [setPhoneError("Phone Number must be filled."), false];
    }
    //minChar
    if (phone.length < minPhone) {
      return [
        setPhoneError("Phone Number must be at least " + minPhone + " digits."),
        false,
      ];
    }
    //maxChar
    if (phone.length > maxPhone) {
      return [
        setPhoneError(
          "Phone Number must be no more than " + maxPhone + " digits."
        ),
        false,
      ];
    }
    return [setPhoneError(null), true];
  };

  const handlePhoneInput = (e) => {
    if (typeof e.target.value === "string") {
      e.target.value = e.target.value.replace(/[^0-9]/g, "");
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === undefined
    ) {
      navigate("/");
    }
    const storedFullName = localStorage.getItem("fullName");
    const storedEmail = localStorage.getItem("email");
    const storedPhone = localStorage.getItem("phone");

    if (storedFullName) {
      setFullName(storedFullName);
      setOriFullName(storedFullName);
    }
    if (storedEmail) {
      setEmail(storedEmail);
      setOriEmail(storedEmail);
    }
    if (storedPhone) {
      setPhone(storedPhone);
      setOriPhone(storedPhone);
    }

    const userId = localStorage.getItem("id");
    userphoto(userId, (photoPath) => {
      if (photoPath) {
        setUserPhoto(`${baseUrl}/${photoPath}`);
      }
    });
  }, []);

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const handleFullNameChange = (e) => {
    if (
      e.target.value === oriFullName &&
      oriPhone === phone &&
      photoChanged.current === false
    ) {
      setIsChanged(false);
    } else {
      setIsChanged(true);
    }
    const newFullName = e.target.value;
    // localStorage.setItem("UpdatedFullName", newFullName);
    setFullName(newFullName);
    // localStorage.setItem("fullName", newFullName);
  };

  // const handleEmailChange = (e) => {
  //   setEmail(e.target.value);
  // };

  const handlePhoneChange = (e) => {
    if (
      e.target.value === oriPhone &&
      oriFullName === fullName &&
      photoChanged.current === false
    ) {
      setIsChanged(false);
    } else {
      setIsChanged(true);
    }
    const newPhone = e.target.value;
    setPhone(newPhone);
    // localStorage.setItem("phone", newPhone);
  };

  //add photo and preview
  // function handleFileChange(e) {
  //   if (e.target.files && e.target.files.length > 0) {
  //     const selectedFile = e.target.files[0]?.name;
  //     setTesPhoto(selectedFile);
  //     handleUserPhoto(selectedFile);
  //     console.log(selectedFile);
  //   }
  // }
  // function handleFileChange(e) {
  //   if (e.target.files && e.target.files.length > 0) {
  //     const selectedFile = e.target.files[0];
  //     setTesPhoto(selectedFile);
  //     handleUserPhoto(selectedFile);
  //     console.log(selectedFile.name);
  //   }
  // }
  // const handleAddPhotoClick = () => {
  //   fileInputRef.current.click();
  // };

  // const handleSaveButton = async () => {
  //   console.log("Full Name:", fullName);
  //   console.log("Email:", email);
  //   console.log("Phone Number:", phone);
  //   const isFullNameValid = fullNameValidation();
  //   const isEmailValid = emailValidation();
  //   const isPhoneValid = phoneNumberValidation();
  //   const userId = localStorage.getItem("id");
  //   try {
  //     const updatedUserData = {
  //       id: userId,
  //       fullname: fullName,
  //       email: email,
  //       phone: phone,
  //     };
  //     const updatedData = await changeuserdata(updatedUserData);
  //     console.log("Updated User Data:", updatedUserData);
  //   } catch (error) {
  //     console.error("Error updating user data:", error);
  //   }
  // };

  const handleSaveButton = async (name) => {
    const isFullNameValid = fullNameValidation();
    const isEmailValid = emailValidation();
    const isPhoneValid = phoneNumberValidation();

    if (isFullNameValid[1] && isEmailValid[1] && isPhoneValid[1]) {
      const trimmedFullName = fullName.trim();
      setFullName(trimmedFullName);

      try {
        const userId = localStorage.getItem("id");
        const updatedUserData = {
          id: userId,
          fullname: trimmedFullName,
          email: email,
          phone: phone,
        };
        await handleEditProfile();
        // localStorage.setItem("UpdatedFullName", trimmedFullName);
        // console.clear();

        updateNavbarFullName(name);

        const token = localStorage.getItem("token");
        const Photoconfig = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
          },
        };

        if (!userPhoto) {
          const formData = new FormData();
          formData.append("id_user", userId);
          formData.append("image_user_photo", tesphoto);
          // console.log(tesphoto);
          await adduserphoto(formData, Photoconfig);
        } else if (tesphoto || selectedFile) {
          const formData = new FormData();
          formData.append("id_user", userId);
          formData.append("image_user_photo", tesphoto || selectedFile);
          // console.log(tesphoto ? tesphoto : selectedFile.name);
          await updateUserPhoto(formData, Photoconfig);
        }
        // const storedFullName = localStorage.getItem("UpdatedFullName");
        setFullName(name);
      } catch (error) {
        // console.error("Error updating user data:", error);
      }
      setIsChanged(false);
      window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }
    // setIsChanged(false)
  };

  const handleEditProfile = async () => {
    try {
      const userId = localStorage.getItem("id");
      const updatedUserData = {
        id: userId,
        fullname: fullName,
        email: email,
        phone: phone,
      };
      // localStorage.setItem("UpdatedFullName", fullName);
      // const UpdatedFullName = localStorage.getItem("UpdatedFullName");
      // console.log("nama lama:", fullName);
      // console.log("nama baru2:", UpdatedFullName);

      const updatedData = await changeuserdata(updatedUserData);
      // console.log(updatedData);
      setShowSuccessEdit(true);
    } catch (e) {
      throw e;
    }
  };

  //connect user photo to api

  // const token = localStorage.getItem('token');

  // const Photoconfig = {
  //   headers: {
  //     'Content-Type': 'multipart/form-data',
  //     'Authorization': 'Bearer ' + token
  //   }
  // };
  // const config = {
  //   headers: {
  //     'Content-Type': 'application/x-www-form-urlencoded',
  //     'Authorization': 'Bearer ' + token
  //   }
  // }

  // const handleUserPhoto = async (selectedFile) => {
  //   try {
  //     const userId = localStorage.getItem("id");
  //     const formData = new FormData();
  //     formData.append("id_user", userId);
  //     formData.append("image_user_photo", selectedFile);
  //     const ProfilePicture = await adduserphoto(formData);
  //     console.log("Profile Picture Response:", ProfilePicture);
  //     setShowModal(false);
  //   } catch (e) {
  //     throw e;
  //   }
  // };

  function handleFileChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setTesPhoto(selectedFile);
      // console.log(selectedFile.name);
      setShowModal(false);
      setIsChanged(true);
      photoChanged.current = true;
    }
  }

  // const handleDeletePhoto = async () => {
  //   try {
  //     const userId = localStorage.getItem("id");
  //     await deletephoto(userId);
  //     setUserPhoto("");
  //   } catch (error) {
  //     console.error("Error deleting photo:", error);
  //   }
  // };

  // const handleUpdatePhoto = async () => {
  //   try {
  //     if (tesphoto) {
  //       const userId = localStorage.getItem("userid");
  //       const formData = new FormData();
  //       formData.append("id_user", userId);
  //       formData.append("image_user_photo", tesphoto);
  //       await updateUserPhoto(formData);
  //     }
  //   } catch (error) {
  //     console.error("Error updating user photo:", error);
  //   }
  // };

  // ...

  const ModalContent = () => {
    if (!userPhoto) {
      return (
        <Container
          fluid
          className="d-flex flex-column align-items-center ModalContentContainer"
        >
          <Row className="RowModalContent">
            <Button
              className="AddPhotoButton btn-addphoto"
              style={{ color: "black" }}
              onClick={() => fileInputRef.current.click()}
            >
              Add Photo
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleFileChange}
              />
            </Button>
          </Row>
          <hr className="EditProfileHr" />
          <Row className="RowModalContent">
            <Button
              variant="secondary"
              className="btn-cancelphoto"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
          </Row>
        </Container>
      );
    } else {
      return (
        <Container
          fluid
          className="d-flex flex-column align-items-center ModalContentContainer"
        >
          <Row className="RowModalContent">
            <Button
              className="RemovePhotoButton btn-edit-photo"
              style={{ color: "black" }}
              onClick={() => fileInputRef.current.click()}
            >
              Upload Photo
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </Button>
          </Row>
          <hr className="EditProfileHr" />
          <Row className="RowModalContent">
            <Button
              variant="secondary"
              className="btn-cancelphoto"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
          </Row>
        </Container>
      );
    }
  };

  // show succses after change user data

  const SuccessModal = () => {
    if (showSuccessEdit) {
      return (
        <div className="SuccessModalContainer">
          <div className="SuccessMessage">
            The changes to your profile have been saved
          </div>
          <button
            type="button"
            className="btn-close"
            onClick={() => setShowSuccessEdit(false)}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="breadCrumb">
        <Breadcrumb />
      </div>
      <div className="EditProfileOuterContainer">
        <Container className="EditProfileContainer" style={{ padding: 0 }}>
          <Row className="text-center">
            <Col>
              <div className="EditHeadTitle">Edit Profile</div>
            </Col>
            <SuccessModal />
          </Row>
          <Row className="d-flex justify-content-center">
            <Col xs="auto" className="position-relative">
              <Avatar
                className="AvatarContainer"
                sx={{ width: 150, height: 150 }}
                // src={tesphoto ? URL.createObjectURL(tesphoto) : userPhoto}
                // src={tesphoto || userPhoto}
                src={tesphoto ? URL.createObjectURL(tesphoto) : userPhoto}
              />
              <button className="CircleButton" onClick={handleButtonClick}>
                <BiPencil
                  size={24}
                  color="#BE1620"
                  className="CircleButtonLogo"
                />
              </button>
            </Col>
          </Row>
          <Modal
            className="d-flex justify-content-center"
            show={showModal}
            onHide={() => setShowModal(false)}
            centered
          >
            <Modal.Header
              style={{ padding: 0 }}
              className="d-flex justify-content-center EditPhotoHeader"
            >
              <Modal.Title style={{ color: "#BE1620" }}>Edit Photo</Modal.Title>
            </Modal.Header>
            {/* <hr style={{ borderTop: '1px solid #ccc', margin: 0 }} /> */}
            <Modal.Body className="EditProfileModalBody">
              <ModalContent />
            </Modal.Body>
            {/* <Modal.Footer>
              <div className="d-flex justify-content-center w-100">
                <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
              </div>
            </Modal.Footer> */}
          </Modal>
          <Row>
            <Form.Group className="fullname">
              <Form.Label className="EditProfileLabel">Full Name</Form.Label>
              <Form.Control
                type="text"
                className="fullnameInput"
                value={fullName}
                onChange={(e) => handleFullNameChange(e)}
              />
              {fullNameError && (
                <Form.Text className="ErrorMessageValidation">
                  {fullNameError}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="EmailCont">
              <Form.Label className="EditProfileLabel">Email</Form.Label>
              <Form.Control
                type="text"
                className="emailInput"
                value={email}
                // onChange={handleEmailChange}
                disabled
                readOnly
              />
              {emailError && (
                <Form.Text className="ErrorMessageValidation">
                  {emailError}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="phonenumber">
              <Form.Label className="EditProfileLabel">Phone Number</Form.Label>
              <Form.Control
                type="text"
                className="phonenumberInput"
                value={phone}
                onChange={(e) => handlePhoneChange(e)}
                onInput={handlePhoneInput}
              />
              {phoneError && (
                <Form.Text className="ErrorMessageValidation">
                  {phoneError}
                </Form.Text>
              )}
            </Form.Group>
          </Row>
          <Row className="d-flex align-items-center EditProfileBottomContainer">
            <Col xs={8}>
              <Link to="/edit-profile/change-password">
                <button className="ChangePass text-nowrap">
                  Change password
                </button>
              </Link>
            </Col>
            <Col xs={4} className="d-flex justify-content-end">
              {!isLoadingEditProfile ? (
                !isChanged ? (
                  <button className="saveButton-disabled" disabled>
                    Save
                  </button>
                ) : (
                  <button
                    className="saveButton"
                    onClick={() => {
                      handleSaveButton(fullName);
                    }}
                  >
                    Save
                  </button>
                )
              ) : (
                <LoadingSpinner />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default EditProfile;
