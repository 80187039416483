import React, { useState, useEffect } from "react";
import AppPreviewPicture from "../Assets/AppPreviewPicture.png";
import GoogleplayDownloadButton from "../Assets/GoogleplayDownloadButton.png";
import AppstoreDownloadButton from "../Assets/AppstoreDownloadButton.png";
import AlertSuccess from "./AlertSuccess";
import validator from "validator";
import AlertFail from "./AlertFail";
import "./Footer.css";
import styles from "./Footer.module.css";
import {
  Container,
  Row,
  Col,
  Image,
  FormGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { getSocialMedia, postSubscribe } from "../Store/Actions/company";
import { Link } from "react-router-dom";

let isServerError = "";


function Footer({ 
  newsletterVisible,
  productVisible,
  aboutVisible,
  contactVisible, 
}) {
  
  let sideLinks = [
    {
      visible: productVisible,
      to: "/product",
      text: "Product",
    },
    {
      visible: "true",
      to: "/about-us",
      text: "About Us",
    },
    {
      visible: contactVisible,
      to: "/contact-us",
      text: "Contact Us",
    },
  ]

  const dispatch = useDispatch();
  // const [newsletterVisible, setNewsletterVisible] = useState(newsletterVisible);
  const [isLoading, setIsLoading] = useState(false);
  const [alertVisible, setAlertVisible] = useState(false);
  // const [isServerError, setIsServerError] = useState(null);
  const { socialmedia } = useSelector((state) => state.company);
  const showSocialMedia = async () => {
    try {
      await dispatch(getSocialMedia());
    } catch (e) {
      // console.log(e);
    }
  };
  useEffect(() => {
    showSocialMedia();
  }, []);

  // console.log(socialmedia?.[0]?.data[1].link_social)
  // console.log(socialmedia[0]?.data[2].link_social)
  const [emailError, setEmailError] = useState("");
  const [data, setData] = useState({
    email_subscribe: "",
  });

  const validateEmail = async (stringEmail) => {
    if (validator.isEmail(stringEmail)) {
      await handleSubmit();
      setData({ email_subscribe: "" });
      if (!isServerError) {
        setAlertVisible(true);
        setEmailError(<AlertSuccess />);
      } else {
        // console.log(isServerError)
        setAlertVisible(true);
        setEmailError(<AlertFail errorResponse={isServerError} />);
      }
    } else {
      setAlertVisible(true);
      setEmailError(<AlertFail />);
    }
    setIsLoading(false);
    const timeoutId = setTimeout(() => {
      setAlertVisible(false);
    }, 2500);
    return () => {
      clearTimeout(timeoutId);
    };
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      isServerError = await dispatch(postSubscribe(data));
      // console.log(isServerError);
    } catch (e) {
      throw e;
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <footer className="footer-boot">
      <Container fluid>
        <Row style={{ paddingTop: "1.875rem", paddingBottom: "1rem" }}>
          <Col  lg={4} xs={6} className="categoryLinkList">
            <h1 className="footer-heading">Categories</h1>
            <div className="linkList">
              { 
                sideLinks.map((sideLink, index) => {
                  let className = index > 0 ? "footer-text linked2" : "footer-text linked";
                  return(
                    sideLink.visible === "true" &&
                      <Link 
                        key={index}
                        className={className}
                        onClick={scrollToTop}
                        to={sideLink.to}
                        >
                          {sideLink.text}
                      </Link>
                  )
                })
              }
            </div>
          </Col>
          <Col lg={4} xs={6}>
            <h1 className="footer-heading">Follow Us</h1>
            {/* <ul className="contacts-link">
              <li>
                <a
                  href={socialmedia?.[0]?.data[1].link_social}
                  target="_blank"
                  rel="noreferrer"
                  className="centerFB"
                >
                  <FontAwesomeIcon icon={faFacebook} size="lg" />
                  <span>abcacoating</span>
                </a>
              </li>
              <li>
                <a
                  href={socialmedia[0]?.data[2].link_social}
                  target="_blank"
                  rel="noreferrer"
                  className="centerFB"
                >
                  <FontAwesomeIcon icon={faInstagram} size="lg" />
                  <span>abcacoating</span>
                </a>
              </li>
            </ul> */}
            <div className="contacts-link">
              <div>
                <button
                  onClick={() => {
                    window.open(socialmedia?.[0]?.data[1].link_social, "_blank");
                  }}
                  className="contacts-icon"
                >
                  <FontAwesomeIcon icon={faFacebook} size="lg" className="iconFB"/>
                  <p>abcacoating</p>
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    window.open(socialmedia[0]?.data[2].link_social, "_blank");
                  }}
                  className="contacts-icon"
                >
                  <FontAwesomeIcon icon={faInstagram} size="lg" className="iconIG" />
                  <p>abcacoating</p>
                </button>
              </div>
            </div>
          </Col>
          { newsletterVisible && // akses
            <Col lg={4} xs={12} className="newsLetterContainer">
            <h1 className="footer-heading">Newsletter</h1>
            <p className="footer-text">
              Subscribe now to stay up to date with our news and events
            </p>
            <div>
              <FormGroup className="Subscribe">
                <FormControl
                  id="email_subscribe"
                  value={data.email_subscribe}
                  className="Email"
                  placeholder="Email"
                  onChange={(e) => {
                    setData({ email_subscribe: e.target.value });
                  }}
                />
                {!isLoading ? (
                  <Button
                    type="reset"
                    onClick={() => {
                      validateEmail(data.email_subscribe);
                    }}
                    className="SubscribeButton"
                  >
                    SUBSCRIBE
                  </Button>
                ) : (
                  <button className="SubscribeButtonLoading">SUBSCRIBE</button>
                )}
              </FormGroup>
              {alertVisible && (
                <div className="Error-Message">{emailError}</div>
              )}
            </div>
            </Col>
          }
          <Col className="tradeMark2" lg={4} xs={12}>
              @2023 abca coating. All rights reserved.            
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        className="download-app-poster"
        style={{
          paddingTop: "0.677vw",
          paddingLeft: "12.240vw",
          paddingRight: "12.240vw",
        }}
      >
        <Row>
          <Col
            md={6}
            lg={8}
            className="d-flex flex-column justify-content-center"
          >
            <Row>
              <h1 className="poster-text">
                Download Aplikasi ABCA Coating di Handphonemu Sekarang!
              </h1>
            </Row>
            <Row
              className="downloadbuttonrow d-flex justify-content-center"
              style={
                {
                  // marginTop: "2.63vh",
                }
              }
            >
              <div className="d-flex align-items-center">
                <a
                  className="GoogleplayDownloadLink"
                  href="https://play.google.com/store/apps/details?id=com.abca.coating&pcampaignid=web_share"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    className={styles.DownloadButton}
                    alt="Googleplay"
                    src={GoogleplayDownloadButton}
                  />
                </a>
                <a
                  className="AppstoreDownloadLink"
                  href="https://apps.apple.com/us/app/spider-color-app/id1479006777"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    className={styles.DownloadButton}
                    alt="Appstore"
                    src={AppstoreDownloadButton}
                  />
                </a>
              </div>
            </Row>
          </Col>
          <Col md={6} lg={4} className="d-flex justify-content-end">
            <Image
              className=""
              alt="AppPreviewPicture"
              src={AppPreviewPicture}
              fluid
            />
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
