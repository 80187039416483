import React from 'react';
import ImageNotFound from '../Assets/imageNotFound.png';
import './SubcategoryContainer.css';
import { Card, Button, Container, Row, Col, Image } from 'react-bootstrap';
import { baseURL } from '../Store/Actions';


function SubcategoryContainer({image, name, idSubCategory, onClick, subLinkName}) {

    const imageUrl        = `${baseURL}/${(image)}`
    const imageAlt        =  ImageNotFound
    const showSubProduct  = true 

    const handleClick = () => {
      onClick(showSubProduct, idSubCategory, subLinkName);
    }

    return (  
        <div className='subcategoryContainer'>
        <Card className="h-100 border-0 card-subcategory">
          {/* <Card.Img variant='top' src={imageUrl} fluid/>  */}
          {/* <Card.Img variant='top' src={imageUrl} fluid/> */}
          { image 
            ? <Card.Img className='mx-auto' variant='top' src={imageUrl} fluid/>
            : <Card.Img className='mx-auto' variant='top' src={imageAlt} fluid/>
          }
          <Card.Body className='d-flex flex-column'>
            <Card.Text className='subcategoryName'>
              {name}
            </Card.Text>
            <Button 
              size='sm' 
              className='mt-auto w-100 text-nowrap btn-subcategory' 
              variant="primary" 
              onClick={()=> handleClick()}
              >
              View more
            </Button>
          </Card.Body> 
        </Card>
        </div>
      )
} 

export default SubcategoryContainer