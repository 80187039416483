import React from "react";
import './ProductNotFound.css'
import ProductNotFoundIcon from '../Assets/ProductNotFoundIcon.png';
import { Container, Row, Col, Image } from 'react-bootstrap';

function ProductNotFound(){
    return(
    <Container className='empty-container'>
        <Row>
          <Col>
            <Image className='ProductNotFoundIcon mx-auto d-block' src={ProductNotFoundIcon} fluid />
            <div className='empty-header'>PRODUCT UNDER DEVELOPMENT</div>
            <div className='empty-text'>
              Mohon maaf, produk ini sekarang sedang dalam pengembangan, mohon cari produk lain.
            </div>
          </Col>
        </Row>
      </Container>  
    )
}

export default ProductNotFound