import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
  redirect,
} from "react-router-dom";
import LoadingSpinner from "../../component/LoadingSpinner";


function ProductRedirect() {
    
    let link = ''
    const navigate = useNavigate();
    useEffect(() => {
        link = localStorage.getItem('categoryOne');
        navigate(`/product/${link}`)
      }, []);

    return (
        <div style={{
            display: 'flex',
            width: '100%',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <LoadingSpinner />
        </div>
     )
}
export default ProductRedirect;