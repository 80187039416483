import { axiosGet } from "./index.js";

export const getProducts = (id, page, sub) => {
  return async (dispatch) => {
    try {
      const products = await axiosGet("product", {
        page,
        ...(!sub ? { id_category: id } : { id_sub_category: id }),
      });
      // if(products.type === 'error') throw products

      dispatch({
        type: "SET_PRODUCTS",
        products,
      });

      // console.log(products)
      return "success";
    } catch (e) {
      throw e;
    }
  };
};

export const getFavouriteProducts = (id) => {
  return async (dispatch) => {
    try {
      const favourite_products = await axiosGet(
        `product?favourite=true&id_category=${id}`
      );
      // if(products.type === 'error') throw products

      dispatch({
        type: "SET_FAVOURITE_PRODUCTS",
        favourite_products,
      });

      // console.log(favourite_products)
      return "success";
    } catch (e) {
      throw e;
    }
  };
};

export const getSubCategoryName = (id) => {
  return async (dispatch) => {
    try {
      const sub_category = await axiosGet("sub-category", { id: id });
      // if(products.type === 'error') throw products

      dispatch({
        type: "SET_SUB_CATEGORY",
        sub_category,
      });

      // console.log(sub_category)
      return "success";
    } catch (e) {
      throw e;
    }
  };
};

export const getCategoryList = () => {
  return async (dispatch) => {
    try {
      const category_list = await axiosGet("category");
      // if(products.type === 'error') throw products

      dispatch({
        type: "SET_CATEGORY_LIST",
        category_list,
      });

      // console.log(category_list)
      return "success";
    } catch (e) {
      throw e;
    }
  };
};

export const getSubCategoryList = () => {
  return async (dispatch) => {
    try {
      const sub_category_list = await axiosGet("sub-category");
      // if(products.type === 'error') throw products

      dispatch({
        type: "SET_SUB_CATEGORY_LIST",
        sub_category_list,
      });

      // console.log(sub_category_list)
      return "success";
    } catch (e) {
      throw e;
    }
  };
};

export const getCategoryName = (idCategory) => {
  return async (dispatch) => {
    try {
      const category = await axiosGet("category", { id: idCategory });
      // if(products.type === 'error') throw products

      dispatch({
        type: "SET_CATEGORY",
        category,
      });

      // console.log(category)
      return "success";
    } catch (e) {
      throw e;
    }
  };
};

export const getProductsPhoto = (idProduct) => {
  return async (dispatch) => {
    try {
      const photos = await axiosGet("product-photo/idproduct", {
        id_product: idProduct,
      });
      // if(products.type === 'error') throw products

      dispatch({
        type: "SET_PHOTOS",
        photos,
      });

      // console.log(photos)
      return "success";
    } catch (e) {
      throw e;
    }
  };
};
