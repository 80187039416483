import React, { useEffect } from "react";
import "../component/ProductShowCategory.css";
import ProductContainer from "../component/ProductContainer";
import ProductNotFound from "../component/ProductNotFound";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../Store/Actions/product";
import PaginationFunction from "./PaginationFunction";
import { useState } from "react";
import { useCallback } from "react";

function ProductShowCategory({ id, sub }) {
  // console.log("psc - id ", id)
  // console.log("psc - sub ", sub)
  // const subCategory = sub;
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.product);
  // PAGINATION
  const [currentPage, setCurrentPage] = useState(1);
  const handleChangePage = useCallback((currentPage) => {
    setCurrentPage(currentPage);
  }, []);
  //
  const showProduct = async () => {
    try {
      dispatch(getProducts(id, currentPage, sub));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (id > 0) {
      showProduct();
      // console.log("psc - showProduct");
    }
    // console.warn(`${id} ${sub}`)
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [currentPage, id]);

  return (
    <div className="productShowCategory">
      {/* <div>
          <br />
          id : {id}
          <br/> 
          sub : {sub ? "true" : "false"}
          <br/>
          currentPage : {currentPage}
          <br/>
        </div> */}
      {products?.[0]?.totalCount > 0 ? (
        <Container fluid>
          <Row>
            {products?.[0]?.data.map((e) => (
              <Col className="productShowCategoryCol" key={e.id} xs={6} xl={4}>
                <ProductContainer
                  name={e.name_product}
                  description={e.description}
                  image={e.image_product}
                />
              </Col>
            ))}
          </Row>
          <PaginationFunction
            totalPages={products?.[0]?.totalPages}
            currentPage={currentPage}
            onChangePage={handleChangePage}
          />
        </Container>
      ) : (
        <ProductNotFound />
      )}
    </div>
  );
}

export default ProductShowCategory;
