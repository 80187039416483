import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../Store/Actions/user';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './ConfirmLogout.css';

function ConfirmLogout({ show, onClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logoutUser());
    window.location.reload();
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    localStorage.removeItem('fullname');
    localStorage.removeItem('email');
    localStorage.removeItem('phone');
     window.location.href = "/";
  };

  const LogoutAlert = () => {
    return (
      show && (
        <div className='ConfirmLogout' style={{ display: 'block', position: 'initial' }}>
          <Modal.Dialog>
            <Modal.Header closeButton onClick={onClose}>
              <Modal.Title className='modal-title w-100' style={{ fontSize: '32px', color: '#BE1620' }}>
                Logout
              </Modal.Title>
            </Modal.Header>

            <Modal.Body className='ConfirmText'>
              <p>Are you sure you want to logout?</p>
            </Modal.Body>

            <Modal.Footer className='AllButton'>
              <Button onClick={handleLogout} className='ButtonLogout'>
                Logout
              </Button>
              <Button variant='outlined' className='CancelBTN' onClick={onClose}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      )
    );
  };

  return <LogoutAlert />;
}

export default ConfirmLogout;

