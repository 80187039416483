import React from "react";
import styles from "./PrivacyPolicy.module.css";


function PrivacyPolicy() {

    return (
        <div className={styles.privacyPolicy}>
            <div className="DefaultTitlePage">
                Privacy Policy
            </div>
            <div className="DefaultHeaderPage">
                Privacy Policy for Spider Color Apps
            </div>
            <div className="DefaultNormalText">
                Last updated: 7 Dec 2023
            </div>
            <div className="DefaultNormalText">
                Thank you for using Spider Color Apps This Privacy Policy is designed to help you understand how we collect, use, and safeguard your personal information.
            </div>

            <div className={styles.pageHeader}>
                1. Information We Collect
            </div>
            <div className={`DefaultNormalText ${styles.second}`}>
                1.1 Personal Information
            </div>
            <div className={`DefaultNormalText ${styles.secondContent}`}>
                We may collect personal information that you provide directly when using the App, such as name, email and phone number. This information is used solely for account creation.
            </div>
            <div className={`DefaultNormalText ${styles.second}`}>
                1.2 Non-Personal Information
            </div>
            <div className={`DefaultNormalText ${styles.secondContent}`}>
                We may also collect non-personal information, such as device information, usage patterns, and other statistical data, to improve the functionality and user experience of the App.
            </div>

            <div className={styles.pageHeader}>
                2. How We Use Your Information
            </div>
            <div className={`DefaultNormalText ${styles.second}`}>
                We use the collected information for the following purposes:
            </div>

            <ul className={`DefaultNormalText ${styles.secondContent}`}>
                <li> 
                    To provide and maintain the App 
                </li>
                <li> 
                    To improve user experience
                </li>
                <li> 
                    To respond to user inquiries and support requests
                </li>
            </ul>

            <div className={styles.pageHeader}>
                3. Data Sharing
            </div>
            <div className={`DefaultNormalText ${styles.second}`}>
                We do not sell, trade, or otherwise transfer your personal information to outside parties. However, we may share aggregated, non-personal information with third parties for analytics and statistical purposes.
            </div>

            <div className={styles.pageHeader}>
                4. Security
            </div>
            <div className={`DefaultNormalText ${styles.second}`}>
                We implement a variety of security measures to protect the safety of your personal information. However, no method of transmission over the internet or electronic storage is completely secure.
            </div>

            <div className={styles.pageHeader}>
                5. Your Choices            
            </div>
            <div className={`DefaultNormalText ${styles.second}`}>
                You may choose to restrict the collection or use of your personal information. However, this may limit certain features and functionalities of the App.
            </div>

            <div className={styles.pageHeader}>
                6. Changes to this Privacy Policy
            </div>
            <div className={`DefaultNormalText ${styles.second}`}>
                We reserve the right to modify this Privacy Policy at any time. Updates will be reflected with the "Last updated" date above. Continued use of the App after any changes signifies your acceptance of those changes.
            </div>
        </div>
    )
}

export default PrivacyPolicy;