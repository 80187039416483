import product from "./product";
import company from "./company";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  product,
  company,
  //
});

export default rootReducer;