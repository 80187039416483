import React from "react";
import Icon from "../../Assets/IconUnderMaintenance.png";
import styles from "./maintain.module.css";



function PageUnderMaintenance() {
    return (
        <div className={styles.notFoundPage}>
            <div className={styles.Icon}>
                <img src={Icon} />
            </div>
            <div className={styles.bigText}>
                UNDER CONSTRUCTION
            </div>
            <div className={styles.smallText}>
                Sedang dalam pengerjaan, mohon coba lagi nanti.
            </div>
        </div>
    )
}
export default PageUnderMaintenance;