import React, { useState, useDispatch } from "react";
import { useNavigate } from "react-router-dom";
import "./ChangePassword.css";
import {
  Col,
  Container,
  Row,
  Button,
  Form,
  Modal,
  ModalBody,
} from "react-bootstrap";
import { changepass } from "../Store/Actions/user";
import { BiLowVision, BiShow } from "react-icons/bi";
import ErrorIcon from "../Assets/ErrorIcon.png";
import LoadingSpinner from "../component/LoadingSpinner";
import Breadcrumb from "../component/Breadcrumb";
import { useEffect } from "react";

function ChangePassword() {
  const navigate = useNavigate();
  const [changeWarn, setChangedWarn] = useState(false);
  const [passChanged, setChanged] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState({
    oldPassword: null,
    newPassword: null,
    confirmPassword: null,
  });

  const minPassword = 8;
  const maxPassword = 15;

  useEffect(() => {
    if (
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === undefined
    ) {
      navigate("/");
    }
  }, []);

  const handleChangePassword = async () => {
    const email = localStorage.getItem("email");
    // console.log("Email:", email);
    // console.log("Old Password:", oldPassword);
    // console.log("New Password:", newPassword);
    try {
      const response = await changepass(email, oldPassword, newPassword);
      setConfirmPassword("");
      setNewPassword("");
      setOldPassword("");
      setChanged(true);
      setIsLoading(false);
      window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    } catch (error) {
      setIsLoading(false);
      setChanged(false);
      setErrorModalVisible(true);
    }
  };

  const isPassChanged = () => {
    if (newPassword === oldPassword) {
      setChangedWarn(true);
      return false;
    }
    return true;
  };

  const oldPasswordValidation = () => {
    //isEmpty
    if (oldPassword.length === 0) {
      return [
        setErrorMessage((prevError) => ({
          ...prevError,
          oldPassword: "Old Password must be filled",
        })),
        false,
      ];
    }

    return [
      setErrorMessage((prevError) => ({
        ...prevError,
        oldPassword: null,
      })),
      true,
    ];
  };

  const newPasswordValidation = () => {
    const regEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
    const hasValidPassword = regEx.test(newPassword);
    //isEmpty
    if (newPassword.length === 0) {
      return [
        setErrorMessage((prevError) => ({
          ...prevError,
          newPassword: "New Password must be filled",
        })),
        false,
      ];
    }
    //minChar
    if (newPassword.length < minPassword) {
      return [
        setErrorMessage((prevError) => ({
          ...prevError,
          newPassword:
            "New Password must be at least " + minPassword + " characters.",
        })),
        false,
      ];
    }
    //maxChar
    if (newPassword.length > maxPassword) {
      return [
        setErrorMessage((prevError) => ({
          ...prevError,
          newPassword:
            "New Password must be no more than " + maxPassword + " characters.",
        })),
        false,
      ];
    }
    //1 1 1
    if (!hasValidPassword) {
      return [
        setErrorMessage((prevError) => ({
          ...prevError,
          newPassword:
            "New Password must contain at least one lowercase letter, one uppercase letter, and one number.",
        })),
        false,
      ];
    }

    return [
      setErrorMessage((prevError) => ({
        ...prevError,
        newPassword: null,
      })),
      true,
    ];
  };

  const confirmPasswordValidation = () => {
    const regEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
    const hasValidPassword = regEx.test(newPassword);
    if (
      newPassword.length >= minPassword &&
      newPassword.length < maxPassword &&
      hasValidPassword
    ) {
      //isEmpty
      if (confirmPassword.length === 0) {
        return [
          setErrorMessage((prevError) => ({
            ...prevError,
            confirmPassword: "Confirm Password must be filled",
          })),
          false,
        ];
      }
      //Password === RePassword?
      if (newPassword != confirmPassword) {
        return [
          setErrorMessage((prevError) => ({
            ...prevError,
            confirmPassword:
              "Your password don't match. Please enter your password again.",
          })),
          false,
        ];
      }
      return [
        setErrorMessage((prevError) => ({
          ...prevError,
          confirmPassword: null,
        })),
        true,
      ];
    }
    return [
      setErrorMessage((prevError) => ({
        ...prevError,
        confirmPassword: null,
      })),
      false,
    ];
  };

  const handleInputOldPassword = (string) => {
    const regex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/g;
    if (regex.test(string)) {
      return;
    }
    setOldPassword(string);
  };
  const handleInputNewPassword = (string) => {
    const regex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/g;
    if (regex.test(string)) {
      return;
    }
    setNewPassword(string);
  };
  const handleInputConfirmPassword = (string) => {
    const regex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\]/g;
    if (regex.test(string)) {
      return;
    }
    setConfirmPassword(string);
  };

  const validateAll = async () => {
    const oldPasswordValid = oldPasswordValidation()[1];
    const newPasswordValid = newPasswordValidation()[1];
    const confirmPasswordValid = confirmPasswordValidation()[1];

    if (oldPasswordValid && newPasswordValid && confirmPasswordValid) {
      const passChanged = isPassChanged();
      if (passChanged) {
        setIsLoading(true);
        await handleChangePassword();
        setIsLoading(false);
      } else {
        setNewPassword("");
        setConfirmPassword("");
      }
    }
  };

  return (
    <>
      <div className="breadCrumbPwChange">
        <Breadcrumb />
      </div>
      <Col className="PageChangePasswordOuter">
        <div className="PageChangePassword">
          <Container>
            <Row className="text-center">
              {passChanged === true ? (
                <div className="successContainer">
                  <div className="successMsg">
                    Your password has been changed.
                  </div>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() => setChanged(false)}
                  />
                </div>
              ) : null}
              <Col>
                <div className="EditHeadTitle">Change Password</div>
              </Col>
            </Row>

            <Row className="d-flex justify-content-center">
              <Form.Group className="ContainerOld">
                <Form.Label>Enter Old Password</Form.Label>
                <div className="password-input">
                  <Form.Control
                    className="InputOldPass"
                    value={oldPassword}
                    type={showOldPassword ? "text" : "password"}
                    onChange={(event) =>
                      handleInputOldPassword(event.target.value.trim())
                    }
                  />
                  {!showOldPassword ? (
                    <BiShow
                      onClick={() => setShowOldPassword(!showOldPassword)}
                      className="password-toggle"
                    />
                  ) : (
                    <BiLowVision
                      onClick={() => setShowOldPassword(!showOldPassword)}
                      className="password-toggle"
                    />
                  )}
                </div>
                {errorMessage.oldPassword != null ? (
                  <div className="ErrorMessage">{errorMessage.oldPassword}</div>
                ) : null}
              </Form.Group>

              <Form.Group className="ContainerNew">
                <Form.Label>Enter New Password</Form.Label>
                <div className="password-input">
                  <Form.Control
                    className="InputNewPass"
                    value={newPassword}
                    type={showNewPassword ? "text" : "password"}
                    onChange={(event) =>
                      handleInputNewPassword(event.target.value.trim())
                    }
                  />

                  {!showNewPassword ? (
                    <BiShow
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      className="password-toggle"
                    />
                  ) : (
                    <BiLowVision
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      className="password-toggle"
                    />
                  )}
                </div>
                {errorMessage.newPassword != null ? (
                  <div className="ErrorMessage">{errorMessage.newPassword}</div>
                ) : null}
              </Form.Group>

              <Form.Group className="ContainerConfirm">
                <Form.Label>Confirm New Password</Form.Label>
                <div className="password-input">
                  <Form.Control
                    className="InputConfirmPass"
                    value={confirmPassword}
                    type={showConfirmPassword ? "text" : "password"}
                    onChange={(e) =>
                      handleInputConfirmPassword(e.target.value.trim())
                    }
                    onPaste={(e) => {
                      e.preventDefault();
                      return;
                    }}
                  />
                  {!showConfirmPassword ? (
                    <BiShow
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      className="password-toggle"
                    />
                  ) : (
                    <BiLowVision
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      className="password-toggle"
                    />
                  )}
                  {/* </Button> */}
                </div>
                {errorMessage.confirmPassword != null ? (
                  <div className="ErrorMessage">
                    {errorMessage.confirmPassword}
                  </div>
                ) : null}
              </Form.Group>
            </Row>

            <Row className="mt-4">
              <Col xs={12} className="d-flex justify-content-end">
                <button
                  className="BackButton mr-3"
                  onClick={() => navigate(-1)}
                >
                  Back
                </button>
                {!isLoading ? (
                  <button
                    className="SaveButton"
                    onClick={() => {
                      validateAll();
                    }}
                  >
                    Save
                  </button>
                ) : (
                  <LoadingSpinner />
                )}
              </Col>
            </Row>
          </Container>

          {/* modal fail */}
          <Modal
            centered
            show={errorModalVisible}
            onHide={() => setErrorModalVisible(false)}
          >
            <div className="d-flex flex-column justify-content-center align-items-center ChangePasswordPopUpContainer">
              <Modal.Header style={{ padding: 0 }}>
                <img src={ErrorIcon} />
              </Modal.Header>
              <Modal.Body
                style={{ padding: 0 }}
                className="ChangePasswordPopUpMessage"
              >
                Your Old Password is wrong<br />please check again.
              </Modal.Body>
            </div>
          </Modal>
          <Modal
            centered
            show={changeWarn}
            onHide={() => setChangedWarn(false)}
          >
            <div className="d-flex flex-column justify-content-center align-items-center ChangePasswordPopUpContainer">
              <Modal.Header style={{ padding: 0 }}>
                <img src={ErrorIcon} />
              </Modal.Header>
              <Modal.Body
                style={{ padding: 0 }}
                className="ChangePasswordPopUpMessage"
              >
                Old Password must be different<br />than New Password
              </Modal.Body>
            </div>
          </Modal>
        </div>
      </Col>
    </>
  );
}

export default ChangePassword;
