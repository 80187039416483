import axios from "axios";
// const url = process.env.REACT_APP_API
// const url ='https://mocki.io/v1/8b456be6-0ae5-4048-9364-d3cb6bf4707b'
// const url = 'https://abca-rev-api-v2.cranium.id'
const url = 'https://api-v2.abca-indonesia.com'
// const url = "empty";
// const url = "https://abca-rev-api.cranium.id";
const token = localStorage.getItem("token");
const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: "Bearer " + token,
  },
};
const photoconfig = {
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  },
};

export const baseURL = url;

export const axiosGet = async (path, params) => {
  try {
    const { data } = await axios.get(
      path !== "" ? `${url}/${path}` : `${url}`,
      { params }
    );
    // console.log(data.result)
    return data.result;
  } catch (e) {
    throw e;
  }
};

export const axiosPost = async (path, body, isPhotoUpload = false) => {
  try {
    const currentConfig = isPhotoUpload ? photoconfig : config;
    // console.log('body', body)
    const { data } = await axios.post(
      path !== "" ? `${url}/${path}` : `${url}`,
      body,
      currentConfig
    );
    return data;
  } catch (e) {
    throw e;
  }
};

export const axiosPut = async (path, body) => {
  try {
    const { data } = await axios.put(`${url}/${path}`, body, config);
    return data;
  } catch (e) {
    throw e;
  }
};

export const axiosDelete = async (path, body) => {
  try {
    const { data } = await axios.delete(`${url}/${path}`, { data: body });
    return data;
  } catch (e) {
    throw e;
  }
};
