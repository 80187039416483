import React, { useEffect, useState } from "react";
import "../Pages/stylecontactus.css";
import {
  Col,
  Container,
  Row,
  Form,
  Button,
  InputGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";
import { BsCaretDown } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  getContactInfo,
  getSocialMedia,
  postContactUs,
} from "../Store/Actions/company";
import validator from "validator";
import popUpStyles from "../component/PopUp.module.css";
import LoadingSpinner from "../component/LoadingSpinner";
import SuccessIcon from "../Assets/SuccessIcon.png";
import arrowVector from "../Assets/rightVector.svg";
import Breadcrumb from "../component/Breadcrumb";

function ContactUs() {
  const [data, setData] = useState({
    title: "",
    fullname: "",
    phone: "",
    email: "",
    subject: "",
    request: "",
  });
  const [errorMessage, setError] = useState({
    titleError: "",
    fullnameError: "",
    phoneError: "",
    emailError: "",
    subjectError: "",
    requestError: "",
  });

  const [showSuccess, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleInput = (e) => {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    // console.log(newData);
  };

  const maxFullName = 30;

  const maxEmail = 30;

  const minPhone = 10;
  const maxPhone = 15;

  const maxSubject = 30;

  const maxMessage = 200;

  const titleValidation = () => {
    //isEmpty
    if (data.title.trim().length === 0) {
      // return [setError({ titleError: "Title must be picked" }), false];
      return [
        setError((prevErrors) => ({
          ...prevErrors,
          titleError: "Title must be picked.",
        })),
        false,
      ];
    }
    return [
      setError((prevErrors) => ({
        ...prevErrors,
        titleError: "",
      })),
      true,
    ];
  };

  const fullNameValidation = () => {
    //isEmpty
    if (data.fullname.trim().length === 0) {
      return [
        setError((prevErrors) => ({
          ...prevErrors,
          fullnameError: "Full Name must be filled.",
        })),
        false,
      ];
    }
    //maxChar
    if (data.fullname.trim().length > maxFullName) {
      return [
        setError((prevErrors) => ({
          ...prevErrors,
          fullnameError:
            "Full name must be no more than " + maxFullName + " characters.",
        })),
        false,
      ];
    }
    return [
      setError((prevErrors) => ({
        ...prevErrors,
        fullnameError: "",
      })),
      true,
    ];
  };

  const phoneNumberValidation = () => {
    if (data.email.trim().length === 0 || data.phone.length != 0) {
      //isEmpty
      if (data.phone.length === 0) {
        return [
          setError((prevErrors) => ({
            ...prevErrors,
            phoneError: "Phone Number must be filled.",
          })),
          false,
        ];
      }
      //minChar
      if (data.phone.length < minPhone) {
        return [
          setError((prevErrors) => ({
            ...prevErrors,
            phoneError:
              "Phone Number must be at least " + minPhone + " digits.",
          })),
          false,
        ];
      }
      //maxChar
      if (data.phone.length > maxPhone) {
        return [
          setError((prevErrors) => ({
            ...prevErrors,
            phoneError:
              "Phone Number must be no more than " + maxPhone + " digits.",
          })),
          false,
        ];
      }
      return [
        setError((prevErrors) => ({ ...prevErrors, phoneError: "" })),
        true,
      ];
    }
    if (errorMessage.emailError.length === 0) {
      return [
        setError((prevErrors) => ({ ...prevErrors, phoneError: "" })),
        true,
      ];
    }
    return [
      setError((prevErrors) => ({ ...prevErrors, phoneError: "" })),
      false,
    ];
  };

  const emailValidation = () => {
    if (data.phone.length === 0 || data.email.trim().length != 0) {
      //isEmpty
      if (data.email.trim().length === 0) {
        return [
          setError((prevErrors) => ({
            ...prevErrors,
            emailError: "Email must be filled.",
          })),
          false,
        ];
      }
      //maxChar
      if (data.email.trim().length > maxEmail) {
        return [
          setError((prevErrors) => ({
            ...prevErrors,
            emailError:
              "Email must be no more than " + maxEmail + " characters.",
          })),
          false,
        ];
      }
      //isEmail
      if (!validator.isEmail(data.email.trim())) {
        return [
          setError((prevErrors) => ({
            ...prevErrors,
            emailError: "Input Email in this field.",
          })),
          false,
        ];
      }
      return [
        setError((prevErrors) => ({ ...prevErrors, emailError: "" })),
        true,
      ];
    }
    if (errorMessage.phoneError.length === 0) {
      return [
        setError((prevErrors) => ({ ...prevErrors, emailError: "" })),
        true,
      ];
    }
    return [
      setError((prevErrors) => ({ ...prevErrors, emailError: "" })),
      false,
    ];
  };

  const subjectValidation = () => {
    //isEmpty
    if (data.subject.trim().length === 0) {
      return [
        setError((prevErrors) => ({
          ...prevErrors,
          subjectError: "Subject must be filled.",
        })),
        false,
      ];
    }
    //maxChar
    if (data.subject.trim().length > maxSubject) {
      return [
        setError((prevErrors) => ({
          ...prevErrors,
          subjectError:
            "Subject must be no more than " + maxSubject + " characters.",
        })),
        false,
      ];
    }
    return [
      setError((prevErrors) => ({ ...prevErrors, subjectError: "" })),
      true,
    ];
  };

  const messageValidation = () => {
    //isEmpty
    if (data.request.trim().length === 0) {
      return [
        setError((prevErrors) => ({
          ...prevErrors,
          requestError: "Message must be filled.",
        })),
        false,
      ];
    }
    //maxChar
    if (data.request.trim().length > maxMessage) {
      return [
        setError((prevErrors) => ({
          ...prevErrors,
          requestError:
            "Message must be no more than " + maxMessage + " characters.",
        })),
        false,
      ];
    }
    return [
      setError((prevErrors) => ({ ...prevErrors, requestError: "" })),
      true,
    ];
  };

  const handlePhoneInput = (e) => {
    setData((prevDatas) => ({
      ...prevDatas,
      phone: e.target.value.replace(/[^0-9]/g, ""),
    }));
  };

  const validateAll = async () => {
    const titleIsValid = titleValidation()[1];
    const fullNameIsValid = fullNameValidation()[1];
    const phoneNumberIsValid = phoneNumberValidation()[1];
    const emailIsValid = emailValidation()[1];
    const subjectIsValid = subjectValidation()[1];
    const messageIsValid = messageValidation()[1];

    // console.log(errorMessage.requestError);

    if (
      titleIsValid &&
      fullNameIsValid &&
      subjectIsValid &&
      messageIsValid &&
      phoneNumberIsValid &&
      emailIsValid
    ) {
      setIsLoading(true);
      await handleSubmit();
      setIsLoading(false);
      // console.log("is valid");
    }
  };

  const handleSubmit = async () => {
    // e.preventDefault();
    await dispatch(postContactUs(data));
    setShow(true);
  };

  const dispatch = useDispatch();
  const { contactinfo, socialmedia } = useSelector((state) => state.company);
  const showContactInfo = async () => {
    try {
      dispatch(getContactInfo());
    } catch (e) {
      // console.log(e);
    }
  };

  const showSocialMedia = async () => {
    try {
      dispatch(getSocialMedia());
    } catch (e) {
      // console.log(e);
    }
  };

  useEffect(() => {
    showContactInfo();
    showSocialMedia();
  }, []);

  
  const mapUrl = contactinfo?.[0]?.data?.[0].link;
  return (
    <div className="ContactUsContent">
      <div className="breadCrumbContactUS">
        <Breadcrumb />
      </div>
      <Container
        className="ContainerContactUs"
        // style={{ margin: 0, padding: 0 }}
      >
        <div className="DefaultTitlePage">CONTACT US</div>
        <Row>
          <Col lg={6} sm={12}>
            <div className="left">
              <h1 className="header-contactus CompanyName">
                PT ANUGERAH BERKAT CAHAYA ABADI
              </h1>
              {/* <div className="txt"> */}
              <div className="txt">{contactinfo?.[0]?.data?.[0].alamat}</div>
              <div className="txt">
                email :{" "}
                <a
                  className="emailLink"
                  href={`mailto:${socialmedia?.[0]?.data?.[0].link_social}`}
                >
                  {socialmedia?.[0]?.data?.[0].link_social}
                </a>
              </div>
              {/* </div> */}
              <div className="MapImage">
                <iframe
                  title="googlemap"
                  src={mapUrl}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
          </Col>
          <Col lg={6} sm={12}>
            <div className="right">
              <h1 className="header-contactus tips" style={{ padding: 0 }}>
                Need Help? Contact us now
              </h1>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label className="LabelTitle">Title</Form.Label>
                    {/* Form Select tak dapat input */}
                    {/* <Form.Select onChange={(e) => handleInput(e)} id="title" value={data.title} style={{ cursor: 'pointer' }} aria-label="Default select" >
                                        <option disabled hidden></option>
                                        <option value="Mr.">Mr.</option>
                                        <option value="Ms.">Ms.</option>                                    
                                    </Form.Select> */}
                    <div className="DropdownTitle">
                      <Form.Control
                        className="InputField InputFieldShort"
                        as="select"
                        onChange={(e) => handleInput(e)}
                        id="title"
                        value={data.title}
                        style={{ cursor: "pointer" }}
                        aria-label="Default select"
                      >
                        <option disabled hidden></option>
                        <option value="Mr.">Mr.</option>
                        <option value="Ms.">Ms.</option>
                      </Form.Control>
                      <img className="DropdownArrow" src={arrowVector} alt="" />
                    </div>
                    {errorMessage.titleError != "" ? (
                      <div className="ErrorMessageValidation">
                        {errorMessage.titleError}
                      </div>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col></Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      className="InputField"
                      onChange={(e) => handleInput(e)}
                      id="fullname"
                      value={data.fullname}
                      type="text"
                      placeholder=""
                    />
                  </Form.Group>
                  {errorMessage.fullnameError != "" ? (
                    <div className="ErrorMessageValidation">
                      {errorMessage.fullnameError}
                    </div>
                  ) : null}
                </Col>
                {/* <Col>
                                <Form.Group>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control onChange={(e) => handleInput(e)} id="last_name" value={data.last_name} type="text" placeholder=""/>
                                </Form.Group>
                            </Col> */}
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      className="InputField"
                      onChange={(e) => {
                        handlePhoneInput(e);
                      }}
                      id="phone"
                      value={data.phone}
                      type="tel"
                      placeholder=""
                    />
                    {errorMessage.phoneError != "" ? (
                      <div className="ErrorMessageValidation">
                        {errorMessage.phoneError}
                      </div>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      className="InputField"
                      onChange={(e) => handleInput(e)}
                      id="email"
                      value={data.email}
                      type="email"
                      placeholder=""
                    />
                  </Form.Group>
                  {errorMessage.emailError != "" ? (
                    <div className="ErrorMessageValidation">
                      {errorMessage.emailError}
                    </div>
                  ) : null}
                </Col>
              </Row>
              <Form.Group>
                <Form.Label>Subject</Form.Label>
                <Form.Control
                  className="InputField"
                  onChange={(e) => handleInput(e)}
                  id="subject"
                  value={data.subject}
                  type="text"
                  placeholder=""
                />
                {errorMessage.subjectError != "" ? (
                  <div className="ErrorMessageValidation">
                    {errorMessage.subjectError}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group>
                <Form.Label>Message</Form.Label>
                <Form.Control
                  className="InputField InputFieldMessage"
                  onChange={(e) => handleInput(e)}
                  id="request"
                  value={data.request}
                  as="textarea"
                  rows={5}
                />
                {errorMessage.requestError != "" ? (
                  <div className="ErrorMessageValidation">
                    {errorMessage.requestError}
                  </div>
                ) : null}
              </Form.Group>
              <div className="d-flex justify-content-end">
                {!isLoading ? (
                  <Button
                    className="contactusButton btn-contact"
                    variant="primary"
                    onClick={() => {
                      validateAll();
                      // handleSubmit();
                    }}
                  >
                    Submit
                  </Button>
                ) : (
                  <LoadingSpinner />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal
        className={popUpStyles.ModalSuccess}
        style={{ padding: 0 }}
        centered
        show={showSuccess}
        onHide={() => setShow(false)}
      >
        <ModalHeader
          className={popUpStyles.ModalHeaderSuccess}
          style={{ padding: 0 }}
        >
          <h1 className={popUpStyles.ModalHeaderTitleSuccess}>Message Sent</h1>
        </ModalHeader>
        <Container className={popUpStyles.ModalBodyContainerSuccess}>
          <ModalBody
            className={popUpStyles.ModalBodyContentSuccess}
            style={{ padding: 0 }}
          >
            <img
              className={popUpStyles.ModalBodyContentImageSuccess}
              src={SuccessIcon}
            />
            <div style={{ padding: 0 }}>
              Message has been sent, please wait for our reply.
            </div>
          </ModalBody>
          <ModalFooter
            style={{ padding: 0 }}
            className={popUpStyles.ModalFooterSuccess}
          >
            <Button
              onClick={() => {
                setShow(false);
                window.location.reload();
              }}
              className={popUpStyles.ModalFooterButtonSuccess}
            >
              OK
            </Button>
          </ModalFooter>
        </Container>
      </Modal>
    </div>
  );
}
export default ContactUs;
