import { useEffect, useState } from "react";
import { Nav, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import styles from "./Breadcrum.module.css";
import { spaceMaker } from "../Functions/DashToSpaceSwitcher";

const Breadcrumb = ({ customValue }) => {
  const screenWidth = window.innerWidth;
  const navigate = useNavigate();
  let breadcrumb = useBreadcrumbs();
  // console.log(customValue);
  let tempBread = "";
  // console.log(breadcrumb);
  useEffect(() => {
    // console.clear();
    // console.log(customValue);
    // console.log(breadcrumb);
  });

  return screenWidth > 768 ? (
    <div className={`d-flex align-items-center ${styles.breadContainer}`}>
      <div>
        <Link className={styles.linkName} to="/">
          abca-indonesia
        </Link>{" "}
        {">"}
      </div>
      {customValue !== undefined
        ? customValue.map((e, idx) => {
            return idx !== customValue.length - 1 ? (
              <div>
                <Link className={styles.linkName} to={`/product/${e}`}>
                  {spaceMaker(e)}
                </Link>{" "}
                {">"}
              </div>
            ) : (
              <div className={styles.nonLinkName}>{spaceMaker(e)}</div>
            );
          })
        : breadcrumb[breadcrumb.length - 1].key.split("/").map((e, idx) => {
            // console.log(idx);
            // console.log(e);
            if (idx === 0) {
              return;
            }
            if (
              idx !==
              breadcrumb[breadcrumb.length - 1].key.split("/").length - 1
            ) {
              // console.log(e);
              return (
                <div key={e.key}>
                  <Link className={styles.linkName} to={`/${e}`}>
                    {e.replace("-", " ")}
                  </Link>{" "}
                  {" >"}
                </div>
              );
            }
            return (
              <div key={e.key}>
                <div className={styles.nonLinkName}>{e.replace("-", " ")}</div>
              </div>
            );
          })}
    </div>
  ) : null;
};

export default Breadcrumb;
