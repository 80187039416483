import React from 'react'
import "./AlertFail.css"

function AlertFail( { errorResponse } ) {
  return (
    <div className='ContainerAlert'>
        <div className='TextAlert'>
          { !errorResponse 
            ? "Subscribe failed, please check your email again."
            : errorResponse
          }
        </div>
    </div>
  )
}

export default AlertFail